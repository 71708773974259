import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxEditorModule } from 'ngx-editor';
import { NgxPermissionsModule } from "ngx-permissions";

import { OrganizationRepository } from "../organizations/repositories/organization.repository";
import { SharedModule } from "../shared/shared.module";

import { AdminDetailsComponent } from './admin-details/admin-details.component';
import { EditGenderComponent } from './edit-gender/edit-gender.component';
import { EditManagedOrganizationsComponent } from './edit-managed-organizations/edit-managed-organizations.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { EditPhoneComponent } from './edit-phone/edit-phone.component';
import { EditTimeslotPreferencesComponent } from './edit-timeslot-preferences/edit-timeslot-preferences.component';
import { EditUserNameComponent } from './edit-user-name/edit-user-name.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { NewPilotComponent } from './new-pilot/new-pilot.component';
import { NewUserComponent } from './new-user/new-user.component';
import { AdminRepository } from "./repositories/admin.repository";
import { SherpaRepository } from "./repositories/sherpa.repository";
import { TalkerRepository } from "./repositories/talker.repository";
import { UsersRepository } from "./repositories/users.repository";
import { AdminResolver } from "./resolvers/admin.resolver";
import { SherpaResolver } from "./resolvers/sherpa.resolver";
import { TalkerResolver } from "./resolvers/talker.resolver";
import { SendReminderComponent } from './send-reminder/send-reminder.component';
import { SherpaDetailsComponent } from './sherpa-details/sherpa-details.component';
import { TalkerDetailsComponent } from './talker-details/talker-details.component';
import { UsersListComponent } from './users-list/users-list.component';

@NgModule({
  declarations: [
    UsersListComponent,
    NewUserComponent,
    AdminDetailsComponent,
    MyProfileComponent,
    EditPhoneComponent,
    EditPasswordComponent,
    EditTimeslotPreferencesComponent,
    TalkerDetailsComponent,
    SherpaDetailsComponent,
    SendReminderComponent,
    NewPilotComponent,
    EditManagedOrganizationsComponent,
    EditUserNameComponent,
    EditGenderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    NgxEditorModule.forRoot({
      locals: {
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    })
  ],
  exports: [
    UsersListComponent
  ],
  providers: [ UsersRepository, OrganizationRepository, SherpaRepository, TalkerRepository, AdminResolver, TalkerResolver, SherpaResolver, AdminRepository ]
})
export class UsersModule {
}
