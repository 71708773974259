import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatestWith, takeUntil } from "rxjs";

import { ConfigurationRepository } from "../../admin/repositories/configuration.repository";
import { DateDelta, DateDeltaConstantType } from "../../admin/types/constant";
import { TimeSlotsPerDay } from "../../shared/components/calendars/monthly-calendar/monthly-calendar.component";
import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { CreateTrainService } from "../create-train.service";
import { Dynamic } from "../models/dynamic.model";
import { Thematic } from "../models/thematic.model";

@Component({
  selector: 'app-select-date',
  templateUrl: './select-date.component.html',
})
export class SelectDateComponent extends AbstractInFunnelComponent {
  availableDays: number[] = [];

  thematic!: Thematic;

  dynamic!: Dynamic;

  dateSelected?: Date;

  minimumDelayToCreateTrain!: DateDelta;

  availableTimes: TimeSlotsPerDay = [ [], [], [], [], [], [], [] ];

  constructor(protected override readonly funnelService: FunnelService,
              private readonly router: Router,
              private readonly configurationRepository: ConfigurationRepository,
              protected override readonly route: ActivatedRoute,
              private readonly createTrainService: CreateTrainService) {
    super(funnelService, route);
  }


  override ngOnInit() {
    this.createTrainService.thematic$
      .pipe(
        combineLatestWith(this.createTrainService.dynamic$, this.createTrainService.timeslots$, this.configurationRepository.getDateDeltaConstant(DateDeltaConstantType.MINIMUM_DELAY_TO_CREATE_TRAIN)),
        takeUntil(this.destroy$)
      )
      .subscribe(([ thematic, dynamic, timeslots, dateDeltaToCreateTrain ]) => {
        this.minimumDelayToCreateTrain = dateDeltaToCreateTrain.value;

        if (!thematic) {
          this.router.navigate([ 'thematics' ]);
        } else if (!dynamic || timeslots.length === 0) {
          this.router.navigate([ 'thematics', thematic.slug ]);
        } else {
          this.thematic = thematic;
          this.dynamic = dynamic;

          super.ngOnInit();

          this.availableDays = [ ...new Set(timeslots.map(timeslot => timeslot.weekDay)).values() ];
          timeslots.forEach(timeslot => {
            this.availableTimes[timeslot.weekDay].push(timeslot);
          });
        }
      });

    this.createTrainService.dateSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe(date => {
        this.dateSelected = date || undefined;
        this.funnelService.canValidate$.next(!!date);
      });


    this.funnelService.validate$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.router.navigate([ 'thematics', this.thematic.slug, 'create-train', this.dynamic.type, 'time' ]);
      });
  }


  selectDate(date: Date): void {
    this.createTrainService.dateSelected$.next(date);
  }
}
