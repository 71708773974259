import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, map, takeUntil } from "rxjs";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { IconType } from "../../shared/components/icon/icon.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { Content } from "../models/contents.entity";


@Component({
    selector: "app-content-details",
    templateUrl: "./content-details.component.html",
    styleUrls: ["./content-details.component.scss"],
})

export class ContentDetailsComponent extends AbstractInFunnelComponent  {
    
    public content!: Content;

    constructor(
        protected override readonly route: ActivatedRoute,
        protected override readonly funnelService: FunnelService,
    ) {
        super(funnelService, route);
     }

    override ngOnInit(): void {
        this.route.data
        .pipe(
            map(data => data.content),
            filter(train => train instanceof Content),
            takeUntil(this.destroy$)
        )
        .subscribe({
            next: (content: Content) => {
                console.log('Content ', content);
                this.content = content;
            }
        });
    }

    getPillarIcon(pillar: any): IconType {
        if(pillar.name === 'individual'){
            return 'candle';
        }

        if(pillar.name === 'environment'){
            return 'house-rustic';
        }

        if(pillar.name === 'event'){
            return 'storm';
        }

        return 'candle';
    }
}