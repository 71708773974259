<div class="wrapper" *ngIf="!loading" [formGroup]="organizationFormGroup">
  <div>
    <div class="row gap center-children-vertically">
      <h1 class="cooper">{{ organization.name?.toUpperCase() }}</h1>
      <app-icon
        *ngxPermissionsOnly="'admin'"
        (click)="editName()"
        button
        type="edit"
      />
      <app-icon
        *ngxPermissionsOnly="'admin'"
        (click)="deleteOrganization()"
        type="trash"
        button
      ></app-icon>
    </div>
    <p>
      Organisation créée depuis le
      {{ organization.createdAt?.toLocaleDateString() }}
    </p>
  </div>

  <h2>Pilotes</h2>

  <app-button icon="add" (click)="addPilot()">Ajouter un pilote</app-button>

  <br />
  <app-info *ngIf="organization.pilotAssociations">
    <p>
      Pour retirer des pilotes,
      <u class="pointer" (click)="goToQuestions()"
        >veuillez contacter votre partenaire We talk.</u
      >
    </p>
  </app-info>

  <div class="column pilots margin-top">
    <div
      class="pilot row gap"
      *ngFor="let association of organization.pilotAssociations"
    >
      <div class="flex-150 text-bold grow">
        {{ capitalize(association?.pilot?.lastName ?? "") }}
      </div>
      <div class="flex-150 text-bold grow">
        {{ capitalize(association?.pilot?.firstName ?? "") }}
      </div>
      <div class="flex-150">
        Pilote depuis le {{ association.createdAt.toLocaleDateString() }}
      </div>
    </div>
  </div>
  <h2>Thématiques</h2>
  <app-button icon="edit" (click)="editThematics()"
    >Gérer les thématiques</app-button
  >
  <div class="thematic-bloc row gap margin-top">
    <div class="tile">
      <div class="text-xl text-primary cooper">
        {{
          allThematics.length - (organization.forbiddenThematics?.length ?? 0)
        }}
      </div>
      <div class="cooper text-black">thématiques accessibles</div>
    </div>
    <div class="tile">
      <div class="text-xl text-secondary cooper">
        {{ organization.forbiddenThematics?.length ?? 0 }}
      </div>
      <div class="cooper text-black">thématiques bloquées</div>
    </div>
  </div>
  <h2>Gestion des unités</h2>

  <div class="row gap center-children-vertically" *ngIf="canAddUnits">
    <p>Unités disponibles: {{ units }}</p>
    <app-button (click)="editUnits()">Ajouter des unités</app-button>
  </div>

  <div class="column margin-bottom margin-top">
    <p class="text-bold">Quota par talker</p>
    <div class="half-width row gap center-children-vertically">
      <div class="width-150" (click)="onSelectQuotaClick()">
        <app-input
          formControlName="talkerQuota"
          [margin-bottom]="false"
          type="number"
          [dropdownData]="quotaPossibilities"
        ></app-input>
      </div>
      <app-checkbox formControlName="unlimitedQuota">Illimité</app-checkbox>
    </div>
  </div>

  <app-info>Une unité = 1 parcours de 4 séances</app-info>

  <h2>Modération des parcours</h2>
  <div class="column gap">
    <app-checkbox (valueChange)="handleCheckboxChange()" formControlName="extraOrganizationMatching"
      >Je souhaite que mes collaborateurs puissent rencontrer des collaborateurs
      d'autres organisations.
    </app-checkbox>
    <app-checkbox (valueChange)="handleCheckboxChange()" formControlName="intraOrganizationMatching"
      >Je souhaite que mes collaborateurs puissent se rencontrer lors des
      parcours.
    </app-checkbox>
  </div>
  <br />
  <app-info
    >Si vous décochez les deux cases ci-dessus, vos collaborateurs ne pourront
    rencontrer personne lors de leur parcours
  </app-info>

  <div *ngIf="forbiddenOrganizationsCheckboxes.length">
    <p class="margin-top margin-bottom">
      Je souhaite que mes collaborateurs ne puissent pas rencontrer des
      collaborateurs des organisations suivantes:
    </p>
    <app-checkmarks
      [checkboxes]="forbiddenOrganizationsCheckboxes"
      [isFemaleWord]="true"
      item-name="Organisation interdite"
      (changeSelection)="updateForbiddenOrganizationsSelection($event)"
    ></app-checkmarks>
  </div>

  <div *ngIf="organization.id">
    <h2>Organisations rattachées</h2>
    <app-button icon="add" (click)="addChild()"
      >Ajouter une organisation rattachée</app-button
    >

    <div class="organizations-list margin-top">
      <div
        class="organization-item row gap center-children-vertically"
        *ngFor="let organization of organization.children"
      >
        <div class="organization-icon center-children">
          <app-icon type="building" class="mobile-hidden" />
          <app-icon type="building" class="desktop-hidden" size="10" />
        </div>
        <p class="flex-200">{{ organization.name.toUpperCase() }}</p>
        <p class="flex-200">
          Créée le {{ organization.createdAt.toLocaleDateString() }}
        </p>
        <div class="row gap">
          <app-button
            (click)="switchOrganization(organization)"
            [outline]="true"
            icon="arrow-right"
            >Aller vers ce compte
          </app-button>
        </div>
      </div>
    </div>
    <div class="margin-top" *ngIf="!organization.children?.length">
      Aucune organisation n'est rattachée
    </div>
  </div>
</div>
