import { booleanAttribute, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { SnackbarService } from "../snackbar/snackbar.service";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: [ './upload-file.component.scss' ]
})
export class UploadFileComponent implements OnInit {
  constructor(private readonly snackBarService: SnackbarService) {
  }

  fileName?: string;

  acceptingMimeTypes!: string;

  @Input({ transform: booleanAttribute }) image = false;

  @Input({ transform: booleanAttribute }) update = false;

  @Input({ transform: booleanAttribute }) hidden = false;

  @Input({ transform: booleanAttribute }) outline = false;

  @Input({ transform: booleanAttribute }) link = false;

  @Output() updateFile = new EventEmitter<File>();

  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  ngOnInit() {
    this.acceptingMimeTypes = this.image ? '.png,.jpg,.jpeg' : '.csv';
  }

  get message() {
    return (this.image && !this.link ?
      (this.update ? "Modifier l'image" : 'Ajouter une image')
      : "");
  }

  onFileSelected(event: EventTarget | null) {
    const file = (event as HTMLInputElement).files?.[0];

    if (file) {
      if (file.size > 1E6) {
        this.snackBarService.pushMessage('La taille de l\'image doit être inférieure à 1Mo', 'error');
        return;
      }

      this.fileName = file.name;
      this.updateFile.emit(file);
    }
  }

  click() {
    this.fileInput.nativeElement.click();
  }
}
