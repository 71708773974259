<app-card>
  <ng-container class="pointer">
    <p>
      <strong
        >{{ organization.name.toUpperCase() }} {{ parentDescription }}</strong
      >
    </p>
    <div>
      <span>{{ organizationInfos }}</span>
    </div>
  </ng-container>
</app-card>
