import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { createWidget } from '@typeform/embed';
import { filter, interval, Subject, switchMap, take, takeUntil } from 'rxjs';

import { TypeformService } from '../typeform.service';
import {
  TypeformFormType,
  TypeformHiddenFields,
  TypeformResponseWithPageResult,
} from '../typeform.type';

@Component({
  selector: 'app-typeform-form',
  templateUrl: './typeform-form.component.html',
  styleUrls: ['./typeform-form.component.scss'],
})
export class TypeformFormComponent implements AfterViewInit, OnDestroy {
  @Input() hiddenFields!: TypeformHiddenFields;

  @Input() type!: TypeformFormType;

  @ViewChild('form') form!: ElementRef<HTMLElement>;

  private destroy$ = new Subject<void>();

  private readonly typeformIdRecord: Record<TypeformFormType, string> = {
    [TypeformFormType.INITIAL]: 'stX34Emj',
    [TypeformFormType.POST_SESSION]: 'eXWd45u8',
    [TypeformFormType.POST_TRAIN]: 'bR2JKdm0',
    [TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN]: 'jrDC1gkY',
  };

  constructor(
    private readonly typeformService: TypeformService,
    private readonly router: Router,
  ) {}

  unmount!: () => void;

  ngAfterViewInit() {
    if (this.type) {
      const { unmount } = createWidget(this.typeformIdRecord[this.type], {
        container: this.form.nativeElement,
        onQuestionChanged: () => {
          this.typeformService.needConfirmToQuit = true;
        },
        onEndingButtonClick: () => {
          this.typeformService.needConfirmToQuit = false;
        },
        onSubmit: () => {
          this.typeformService.needConfirmToQuit = false;
          if (this.typeformService.talker) {
            if (this.type !== TypeformFormType.POST_SESSION) {
              interval(2000)
                .pipe(
                  switchMap(() =>
                    this.typeformService.retrieveResponse(
                      this.type,
                      this.hiddenFields.id_parcours,
                    ),
                  ),
                  filter(
                    (response): response is TypeformResponseWithPageResult =>
                      response !== null,
                  ),
                  take(1),
                  takeUntil(this.destroy$),
                )
                .subscribe((response) => {
                  this.typeformService.refreshProfile().subscribe(() => {
                    this.typeformService.goToResult(response);
                  });
                });
            } else {
              setTimeout(() => {
                this.router.navigate(['my-space']);
              }, 5000);
            }
            // Fix it
            // this.typeformService.saveResponse({
            //   responseId,
            //   type: this.type,
            //   talkerId: this.hiddenFields.id_user
            // }).pipe(
            //   filter(() => !!this.profileService.profile),
            //   delay(3000))
            //   .subscribe(() => {
            //     if (this.type === TypeformFormType.INITIAL || TypeformFormType.POST_SIX_MONTHS) {
            //       this.router.navigate(['diagnostic', this.type]);
            //     } else {
            //       this.router.navigateByUrl(this.navigationService.lastPage);
            //     }
            //   });
          }
        },
        displayAsFullScreenModal: true,
        fullScreen: true,
        hidden: this.hiddenFields,
      });
      this.unmount = unmount;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.unmount();
  }
}
