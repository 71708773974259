import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject, takeUntil } from "rxjs";

import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { IPaginationComponent } from "../../shared/repositories/pagination.interface";
import { CsvService } from "../../shared/services/csv.service";
import { FilterStateService } from "../../shared/services/filter-state.service";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Organization } from "../models/organizations.entity";
import { NewOrganizationComponent } from "../new-organization/new-organization.component";
import {
  OrganizationFilterForm,
  OrganizationPropertiesFilter,
  OrganizationRepository,
} from "../repositories/organization.repository";

@Component({
  selector: "app-organizations-list",
  templateUrl: "./organizations-list.component.html",
  styleUrls: ["./organizations-list.component.scss"],
})
export class OrganizationsListComponent
  implements
    IPaginationComponent<Organization, OrganizationPropertiesFilter>,
    OnInit, AfterViewInit, OnDestroy {
  mainRepository: OrganizationRepository;

  filterForm!: EntityFormGroup<OrganizationPropertiesFilter>;

  extractLoading = false;

  newItems$ = new Subject<Organization[]>();

  sortBy$ = new BehaviorSubject<{ sort: string; order: "ASC" | "DESC" }>({ sort: 'name', order: 'ASC' });

  propertiesFilters$ = new BehaviorSubject<
    Partial<OrganizationPropertiesFilter>
  >({});

  hasFilters = false;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly organizationsRepository: OrganizationRepository,
    private readonly dialog: DialogService,
    private readonly formBuilder: FormBuilder,
    private readonly csvService: CsvService,
    private readonly snackBarService: SnackbarService,
    private filterStateService: FilterStateService
  ) {
    this.mainRepository = this.organizationsRepository;
    const prevUrl = this?.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    const pattern = /^\/organizations\/([^\/]+)$/;
    const isMatch = !!prevUrl?.match(pattern);

    if(!isMatch){
      this.filterStateService.state$.next(null);
    }

    this.filterForm = this.formBuilder.group({
      startCreationDate: new FormControl("", { nonNullable: true }),
      endCreationDate: new FormControl("", { nonNullable: true }),
      trainsCountMinimum: new FormControl("", { nonNullable: true }),
      trainsCountMaximum: new FormControl("", { nonNullable: true }),
      membersCountMinimum: new FormControl("", { nonNullable: true }),
      membersCountMaximum: new FormControl("", { nonNullable: true }),
      unitsMinimum: new FormControl("", { nonNullable: true }),
      unitsMaximum: new FormControl("", { nonNullable: true }),
    });
    
    this.route.queryParamMap
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        if (queryParams.has("create")) {
          this.addOrganization();
        }
      });
  }

  clearFilter?: (() => void) | undefined;

  otherFilterOptionCount?: number | undefined;

  savePreferences?: ((filter: OrganizationFilterForm) => void) | undefined;

  getFilter?: (() => OrganizationFilterForm) | undefined;

  ngOnInit() {
    this.propertiesFilters$.subscribe((propertiesFilters) => {
      const hasValue = Object.values(propertiesFilters).some(value => !!value);
      this.hasFilters = hasValue;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngAfterViewInit() {
    if(this.filterStateService.state$.getValue()){
      this.filterForm.setValue(this.filterStateService.state$.getValue());
    }
  }

  public addOrganization(): void {
    this.dialog
      .open(NewOrganizationComponent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((newOrganization) => {
        if (newOrganization?.name) {
          this.organizationsRepository.create(newOrganization).subscribe({
            next: (organisationCreated) => {
              this.snackBarService.pushMessage(
                "Organisation créée avec succès",
                "success"
              );
              this.newItems$.next([organisationCreated]);
            },
          });
        }
      });
  }

  public goToDetails(id: string) {
    if (this.filterForm.dirty) {
      this.filterStateService.state$.next(this.filterForm.value);
    }
    this.router.navigate(["organizations", id]);
  }

  downloadOrganizationsCsv(): void {
    this.extractLoading = true;
    this.organizationsRepository.paginate(
      { 
        page: 1, 
        pageSize: 100000, 
        properties: this.propertiesFilters$.getValue(), 
        sortBy: this.sortBy$.getValue(),
      }).subscribe((data) => {
      this.extractLoading = false;
      this.csvService.downloadOrganizationsCsv(data.items);
    });
  }
}
