import {
  booleanAttribute,
  Component,
  Input,
  numberAttribute,
} from "@angular/core";

export type IconType =
  | "bubble-smile"
  | "menu"
  | "leaves"
  | "check"
  | "dots"
  | "heart"
  | "heart-file"
  | "house"
  | "suit-case"
  | "calendar"
  | "favorite"
  | "favorite-outline"
  | "user"
  | "user-profile-right"
  | "users"
  | "building"
  | "smile"
  | "check-dot"
  | "info"
  | "danger"
  | "bell"
  | "clock"
  | "play"
  | "email"
  | "lock"
  | "search"
  | "phone"
  | "chart"
  | "power"
  | "parameters"
  | "crawn"
  | "gauge"
  | "edit"
  | "arrow-left"
  | "arrow-right"
  | "arrow-down"
  | "chevron-left"
  | "chevron-down"
  | "chevron-right"
  | "chevron-up"
  | "close"
  | "trash"
  | "add"
  | "exchange"
  | "upload"
  | "filter"
  | "eye-open"
  | "eye-closed"
  | "options"
  | "trash-primary"
  | "sad-emoji"
  | "woozy-emoji"
  | "neutral-emoji"
  | "happy-emoji"
  | "laughy-emoji"
  | "award-prize"
  | "hands-heart"
  

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
  @Input() type!: IconType;

  @Input({ transform: booleanAttribute }) button: boolean = false;

  @Input({ transform: numberAttribute }) size?: number;

  @Input() color?: string;

  getSvgFile(type: IconType): string {
    return `assets/icons/${type}.svg`;
  }
}
