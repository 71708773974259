<div class="media-card margin-top">
    <div *ngIf="isVideo; else noVideo" class="media">
      <iframe width="100%" height="auto" [src]="youtubeUrl(content.resource)" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <ng-template #noVideo>
      <div class="no-video-container" (click)="handleNoVideoClick($event)">
        <div *ngIf="content.image; else noImage" class="image-container">
          <img [src]="content.image" alt="content.title">
        </div>
        <ng-template #noImage>
          <div class="icon-container">
            <app-icon [type]="getIcon()" size="150"></app-icon>
          </div>
        </ng-template>
      </div>
    </ng-template>
    <div class="details">
      <h2 class="card-title">{{ content.title }}</h2>
      <div class="row gap">
        <div *ngFor="let pillar of content.pillars">
          <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
        </div>
        <div class="category-container" *ngFor="let thematicCategory of content.thematicCategories">
          {{ thematicCategory.slug }}
        </div>
      </div>
      <div class="margin-top">
        <p class="description-container">
            {{ content.description }}
        </p>
      </div>
      <div *ngIf="content.thematics.length" class="margin-top padding-bottom thematic-container">
          Thématiques associées: {{associatedThematics}} 
          <span *ngIf="content.thematics.length > 2">; {{ remainingThematicslength }}</span>
      </div>
    </div>
</div>