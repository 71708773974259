import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { combineLatestWith, Subject, takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { TrackerAction, TrackerCategory } from '../../shared/types/tracker';
import { mediumCategoryColors } from "../../thematics/colors";
import { CreateTrainService } from "../../thematics/create-train.service";
import { ThematicCategory, ThematicCategorySlug } from "../../thematics/models/category.model";
import { DynamicType } from "../../thematics/models/dynamic.model";
import { Thematic } from "../../thematics/models/thematic.model";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { sortTrains, Train } from "../models/train.entity";
import { TrainRepository } from "../repositories/train.repository";

export type TrainByThematic = { thematic: Thematic, trains: Train[] };

@Component({
  selector: 'app-available-trains',
  templateUrl: './available-trains.component.html',
  styleUrls: [ './available-trains.component.scss' ]
})
export class AvailableTrainsComponent implements OnInit, OnDestroy {

  loading = true;

  destroy$ = new Subject<void>();

  availableTrainsByThematics: TrainByThematic[] = [];

  availableTrainsByThematicsFiltered: TrainByThematic[] = [];

  availableTrains: Train[] = [];

  categories: ThematicCategory[] = [];

  categoryCheckboxes: Checkbox<ThematicCategorySlug>[] = [];

  categoryCheckboxesSelected: Checkbox<ThematicCategorySlug>[] = [];

  onlyFavorites = false;

  favoriteThematics: Thematic[] = [];

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [ {
    id: DynamicType.COACHING,
    key: "Coaching en groupe",
    selected: false,
  },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  dynamicTypeCheckboxesSelected: Checkbox<DynamicType>[] = [];

  dateForm!: EntityFormGroup<{ since: string, until: string }>;

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly trainRepository: TrainRepository,
              private readonly createTrainService: CreateTrainService,
              private readonly profileService: ProfileService,
              private readonly thematicRepository: ThematicRepository,
              private readonly formBuilder: FormBuilder,
              public readonly funnelService: FunnelService,
              private readonly router: Router) {
    this.dateForm = this.formBuilder.group({
      since: new FormControl('', { validators: Validators.required, nonNullable: true }),
      until: new FormControl('', { validators: Validators.required, nonNullable: true })
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.createTrainService.clear();
    this.dateForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filter();
      });
    this.trainRepository.getAvailableTrains()
      .pipe(
        combineLatestWith(this.thematicRepository.getCategories(), this.profileService.getMyProfile()),
        takeUntil(this.destroy$))
      .subscribe(([ trains, categories, profile ]) => {

        this.favoriteThematics = profile.role === Role.Talker ? profile.favoriteThematics ?? [] : [];
        this.categories = categories;
        this.categoryCheckboxes = categories.map(category => ({
          selected: false,
          id: category.slug,
          key: category.name
        }));
        this.availableTrainsByThematics = trains.reduce((trainsByThematic, train) => {
          const existingThematic = trainsByThematic.find(e => e.thematic.id === train.dynamic.thematic.id);
          if (existingThematic) {
            existingThematic.trains.push(train);
          } else {
            trainsByThematic.push({
              thematic: { ...train.dynamic.thematic, dynamics: [ train.dynamic ] },
              trains: [ train ]
            });
          }
          return trainsByThematic;
        }, [] as TrainByThematic[]);

        this.availableTrains = this.availableTrainsByThematics.reduce((availableTrains, trainsByThematic) => {
          availableTrains.push(...trainsByThematic.trains);
          return availableTrains;
        }, [] as Train[]).sort(sortTrains).slice(0, 5);

        this.filter();
        this.loading = false;
      });
  }

  goToThematics(): void {
    this.tracker.trackEvent(TrackerCategory.TRAINS_TALKER, TrackerAction.CLICK, 'discoverThematicButton');
    this.router.navigate([ 'thematics' ]);
  }

  selectCategory(slug: string) {
    const category = this.categoryCheckboxes.find(c => c.id === slug);
    if (category) {
      category.selected = !category.selected;
    }
    this.categoryCheckboxesSelected = this.categoryCheckboxes.filter(checkbox => checkbox.selected);

    this.filter();
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected = this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected);

    this.filter();
  }

  selectFavorite() {
    this.onlyFavorites = !this.onlyFavorites;
    this.filter();
  }

  filter() {
    this.availableTrainsByThematicsFiltered = this.availableTrainsByThematics
      .filter(trainsByThematic =>
        !this.categoryCheckboxes.filter(checkbox => checkbox.selected).length || this.categoryCheckboxes.filter(checkbox => checkbox.selected)
          .map(checkbox => checkbox.id).includes(trainsByThematic.thematic.category?.slug as ThematicCategorySlug)
      )
      .map(trainsByThematic => ({
        ...trainsByThematic,
        trains: trainsByThematic.trains.filter(train => !this.dynamicTypeCheckboxes.filter(d => d.selected).length || this.dynamicTypeCheckboxes.filter(d => d.selected).map(d => d.id).includes(train.dynamic.type))
      }))
      .map(trainsByThematic => ({
        ...trainsByThematic,
        trains: trainsByThematic.trains
          .filter(train => !this.dateForm.controls.since.value || train.getFirstSession().date.getTime() > (new Date(this.dateForm.controls.since.value)).getTime())
          .filter(train => !this.dateForm.controls.until.value || train.getLastSession().date.getTime() < (new Date(this.dateForm.controls.until.value)).getTime())
      }))
      .filter(t => t.trains.length)
      .filter(t => !this.onlyFavorites || this.favoriteThematics.map(ft => ft.id).includes(t.thematic.id));
  }

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly capitalize = capitalize;

  protected readonly DynamicType = DynamicType;
}
