import {
  Component,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter, Input
} from "@angular/core";

import { DropdownPanel } from "./dropdown.panel";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"]
})
export class DropdownComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;

  @Input() closeOnClick = true;

  @Input() isFilter = false;

  @Output() closed = new EventEmitter<void>();

  onClick() {
    if (this.closeOnClick) {
      this.close();
    }
  }

  close() {
    this.closed.emit();
  }

}
