<app-card-pagination
  [mainRepository]="mainRepository"
  [propertiesFilters$]="propertiesFilters$"
  [newItems$]="newItems$"
  [sortBy$]="sortBy$"
  name="organisation"
  [isFemaleName]="true"
  [filterForm]="filterForm"
  (add)="addOrganization()"
  (download)="downloadOrganizationsCsv()"
  [hasFilters]="hasFilters"
>
  <ng-container filter [formGroup]="filterForm">
    <div class="filter">
      <p>Date de création</p>
      <app-input
        title="À partir du"
        formControlName="startCreationDate"
        type="date"
      ></app-input>
      <app-input
        title="Jusqu'au"
        formControlName="endCreationDate"
        type="date"
      ></app-input>
    </div>
    <div class="filter">
      <p>Nombre de membres</p>
      <app-input
        formControlName="membersCountMinimum"
        type="number"
        placeholder="Minimum"
      ></app-input>
      <app-input
        formControlName="membersCountMaximum"
        type="number"
        placeholder="Maximum"
      ></app-input>
    </div>
    <div class="filter">
      <p>Nombre de parcours</p>
      <app-input
        formControlName="trainsCountMinimum"
        type="number"
        placeholder="Minimum"
      ></app-input>
      <app-input
        formControlName="trainsCountMaximum"
        type="number"
        placeholder="Maximum"
      ></app-input>
    </div>
    <div class="filter">
      <p>Nombre de unités disponibles</p>
      <app-input
        formControlName="unitsMinimum"
        type="number"
        placeholder="Minimum"
      ></app-input>
      <app-input
        formControlName="unitsMaximum"
        type="number"
        placeholder="Maximum"
      ></app-input>
    </div>
  </ng-container>
  <ng-template #card let-item>
    <app-organization-card
      class="pointer"
      (click)="goToDetails(item.id)"
      [organization]="item"
    ></app-organization-card>
  </ng-template>
</app-card-pagination>
