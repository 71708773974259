import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { APP_CONFIG } from "../../config/config";
import { AppConfig } from "../../config/config.type";
import { AbstractRepository } from "../../shared/repositories/abstract.repository";
import { FilterInterface, FindResult, IPaginationRepository } from "../../shared/repositories/pagination.interface";
import { CreateUser, Role, User } from "../models/users.entity";

export type UserFilterForm = {
  trainsCountMinimum: string,
  trainsCountMaximum: string,
  startCreationDate: string,
  endCreationDate: string,
  startActivationDate: string,
  endActivationDate: string,
}

export type UserPropertiesFilter = UserFilterForm & {
  role: Role[],
  pilot: boolean,
  organizationIds: string[],
}

@Injectable()
export class UsersRepository extends AbstractRepository<User, CreateUser> implements IPaginationRepository<User, UserPropertiesFilter> {
  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              protected override readonly http: HttpClient) {
    super(http, User);
    this.apiUrl = this.appConfig.apiUrl;
  }

  getEntityName(): string {
    return "users";
  }

  isEmailTaken(email: string): Observable<boolean> {
    return this.http.get<boolean>([ this.apiUrl, this.getEntityName(), 'check-email' ].join('/'), {
      params: { email }
    });
  }

  paginate(filter: FilterInterface<UserPropertiesFilter>): Observable<FindResult<User>> {

    return this.http.post<FindResult<User>>([ this.apiUrl, this.getEntityName(), 'paginate' ].join('/'), filter).pipe(
      map(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => this.instantiate(item))
      }))
    );
  }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>([ this.apiUrl, this.getEntityName(), 'all' ].join('/'))
      .pipe(map(items => items.map(item => this.instantiate(item))));
  }

  getUsersNotActivated(organizationIds?: string[]) {
    return this.http.post<User[]>([ this.apiUrl, this.getEntityName(), 'not-activated' ].join('/'), { organizationIds })
      .pipe(map(items => items.map(item => this.instantiate(item))));
  }
}
