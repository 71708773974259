import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { BehaviorSubject, filter, map, Observable, Subject, switchMap, takeUntil } from "rxjs";

import { AuthenticationService } from "../../authentication/services/authentication.service";
import { Organization } from "../../organizations/models/organizations.entity";
import { OrganizationRepository } from "../../organizations/repositories/organization.repository";
import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { SelectionItem, Tag } from "../../shared/components/tags/tags.component";
import { IPaginationComponent } from "../../shared/repositories/pagination.interface";
import { CsvService } from "../../shared/services/csv.service";
import { FilterStateService } from '../../shared/services/filter-state.service';
import { capitalize } from "../../shared/tool-functions/capitalize";
import { valueNotNull } from "../../shared/tool-functions/not-nullable";
import { computeUserProfileRouterUrl } from "../../shared/tool-functions/user-details-url";
import { EntityFormGroup } from "../../shared/types/entity-form-group";
import { Role, RoleDescription, SubRole, User } from "../models/users.entity";
import { NewUserComponent } from "../new-user/new-user.component";
import { AdminRepository } from "../repositories/admin.repository";
import { SherpaRepository } from "../repositories/sherpa.repository";
import { UserFilterForm, UserPropertiesFilter, UsersRepository } from "../repositories/users.repository";
import { SendReminderComponent } from "../send-reminder/send-reminder.component";


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: [ './users-list.component.scss' ],
})
export class UsersListComponent implements IPaginationComponent<User, UserFilterForm, UserPropertiesFilter>, OnInit, OnDestroy {
  mainRepository!: UsersRepository;

  newItems$ = new Subject<User[]>();

  filterForm!: EntityFormGroup<UserFilterForm>;

  roles$: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);

  roleSelected: SelectionItem[] = (Object.values(RoleDescription).map(description => ({
    id: description,
    name: capitalize(description),
    highlighted: false,
    selected: false,
  })));

  organizations!: Organization[];

  organizationCheckboxes: Checkbox[] = [];

  extractLoading = false;

  updateOrganizationsSelected$ = new Subject<void>();

  propertiesFilters$ = new BehaviorSubject<Partial<UserPropertiesFilter>>({});

  hasFilters = false;

  private destroy$ = new Subject<void>();

  constructor(private readonly userRepository: UsersRepository,
              private readonly sherpaRepository: SherpaRepository,
              private readonly adminRepository: AdminRepository,
              private readonly organizationRepository: OrganizationRepository,
              private readonly dialog: DialogService,
              private readonly snackBarService: SnackbarService,
              private readonly formBuilder: FormBuilder,
              private readonly authService: AuthenticationService,
              private readonly csvService: CsvService,
              private filterStateService: FilterStateService,
              private readonly router: Router) {
    this.mainRepository = this.userRepository;
    const prevUrl = this?.router?.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();
    const pattern = /^\/(talkers|admins|sherpas)\/([^\/]+)$/;
    const isMatch = !!prevUrl?.match(pattern);
    if(!isMatch){
      this.filterStateService.state$.next(null);
    }
    this.filterForm = this.formBuilder.group({
      trainsCountMinimum: new FormControl('', { nonNullable: true }),
      trainsCountMaximum: new FormControl('', { nonNullable: true }),
      startCreationDate: new FormControl('', { nonNullable: true }),
      endCreationDate: new FormControl('', { nonNullable: true }),
      startActivationDate: new FormControl('', { nonNullable: true }),
      endActivationDate: new FormControl('', { nonNullable: true }),
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit(){
    if(this.filterStateService.state$.getValue()){
      const keysToRemove = ['organizationIds', 'role', 'pilot'];
      const formFilters = keysToRemove.reduce((obj, key) => {
        const { [key]: _, ...rest } = obj;
        return rest;
      }, this.filterStateService.state$.getValue());
      this.filterForm.setValue({ ...this.filterForm.value, ...formFilters });

      const filteredFormFilters = Object.fromEntries(
        Object.entries(formFilters).filter(([_, value]) => value !== '')
      );
      
      this.propertiesFilters$.next({
        ...filteredFormFilters,
        role: this.filterStateService.state$.getValue().role,
        pilot: this.filterStateService.state$.getValue().pilot,
        organizationIds: this.filterStateService.state$.getValue().organizationIds,
      });

     if(this.filterStateService.state$.getValue().role){
        this.roles$.next(Object.values(RoleDescription).map(description => ({ id: description,
          name: capitalize(description),
          highlighted: this.filterStateService.state$.getValue().role.includes(description) || (description === 'pilote' && this.filterStateService.state$.getValue().pilot),
          selected: this.filterStateService.state$.getValue().role.includes(description)  || (description === 'pilote' && this.filterStateService.state$.getValue().pilot)
        })));
      } else {
        this.roles$.next(Object.values(RoleDescription).map(description => ({
          id: description,
          name: capitalize(description),
          highlighted: false,
        })));
      }
    } else {
      this.roles$.next(Object.values(RoleDescription).map(description => ({
        id: description,
        name: capitalize(description),
        highlighted: false
      })));
    }

    this.propertiesFilters$.subscribe((propertiesFilters) => {
      const hasValue = Object.values(propertiesFilters).some(value => !!value);
      this.hasFilters = hasValue;
    });

    this.organizationRepository.findAll().subscribe(organizations => {
      this.organizations = organizations;
      this.organizationCheckboxes = organizations.map(organization => ({
        id: organization.id,
        key: organization.name.toUpperCase(),
        selected: this.filterStateService.state$.getValue()?.organizationIds?.includes(organization.id) ?? false,
      }));
    });
  }

  public addUser(): void {
    this.dialog.open(NewUserComponent)
      .pipe(
        filter(valueNotNull),
        switchMap(newUser => (newUser.organization
          ? this.organizationRepository.createMember(newUser, newUser.organization?.id)
          : newUser.role === Role.Sherpa ? this.sherpaRepository.create(newUser) : this.adminRepository.create(newUser))
        ),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (newUser) => {
          this.snackBarService.pushMessage('Utilisateur créé avec succès', 'success');
          this.newItems$.next([ newUser ]);
        }
      })
    ;
  }

  public goToUserDetails(user: User) {
    if (this.filterForm.dirty) {
      this.filterStateService.state$.next({ ...this.filterStateService.state$.value, ...this.filterForm.value });
    }
    this.router.navigate(computeUserProfileRouterUrl(user));
  }

  selectRoles(roles: SelectionItem[]) {
    const rolesSelected = roles.filter(role => role.selected).map(roleDescription => (Object.keys(RoleDescription) as SubRole[]).find(d => RoleDescription[d] === roleDescription.id));
    this.roleSelected = roles;
    
    if (rolesSelected.length && rolesSelected.every(r => !!r)) {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        role: rolesSelected.filter(roleSelected => roleSelected !== 'pilot') as Role[],
        pilot: rolesSelected ? !!rolesSelected.find(role => role === 'pilot') : undefined,
      });

      this.filterStateService.state$.next({
        ...this.propertiesFilters$.value,
        role: rolesSelected.filter(roleSelected => roleSelected !== 'pilot') as Role[],
        pilot: rolesSelected ? !!rolesSelected.find(role => role === 'pilot') : undefined,
      });
    } else {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        role: undefined,
        pilot: false
      });
    }
  }

  updateOrganizationsSelected(organizationCheckboxes: Checkbox[]): void {
    const organizationSelected = organizationCheckboxes.filter(checkbox => checkbox.selected);
    if (organizationSelected.length && organizationSelected.every(o => !!o)) {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        organizationIds: organizationSelected.map(organization => organization.id)
      });
      this.filterStateService.state$.next({
        ...this.propertiesFilters$.value,
        organizationIds: organizationSelected.map(organization => organization.id)
      });
    } else {
      this.propertiesFilters$.next({
        ...this.propertiesFilters$.value,
        organizationIds: undefined
      });
    }
  }

  clearFilter() {
    this.organizationCheckboxes.forEach(checkbox => {
      checkbox.selected = false;
    });
    this.updateOrganizationsSelected([]);
    this.updateOrganizationsSelected$.next();

    this.selectRoles([]);
    this.roles$.next(this.roles$.value.map(role => {
      role.highlighted = true;

      return role;
    }));
  }

  get otherFilterOptionCount(): number {
    return (this.organizationCheckboxes.some(checkbox => checkbox.selected) ? 1 : 0) + (this.roleSelected.some(role => role.selected) ? 1 : 0);
  }

  downloadUsersCsv(): void {
    this.extractLoading = true;
    this.userRepository.paginate(
      {
        page: 1,
        pageSize: 100000,
        properties: this.propertiesFilters$.value,
      }
    )
      .subscribe(data => {
        this.extractLoading = false;
        this.csvService.downloadUsersCsv(data.items);

      });
  }

  sendNewReminders(): void {
    this.dialog.open(SendReminderComponent)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (userIds) => {
          if (userIds?.length) {
            this.authService.sendNewReminders(userIds).subscribe(() => {
              this.snackBarService.pushMessage(`Emails d'inscriptions envoyés à ${ userIds.length } utilisateurs`, 'success');
            });
          }
        },
      })
    ;
  }

  get hasActiveFiltersOrDirtyForm(): Observable<boolean> {
    // Check if propertiesFilters$ has emitted any value
    const hasPropertiesFilters$Value = this.propertiesFilters$.pipe(
      map(value => !!value) // Transform emitted value to boolean
    );

    // Combine the conditions
    // Note: Since hasPropertiesFilters$Value is an Observable, you might need to handle this getter differently in your template or logic, possibly using async pipe or subscribing to it.
    return hasPropertiesFilters$Value.pipe(
      map(hasValue => this.filterForm.dirty || hasValue)
    );
  }
}
