import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, Observable, skip, Subject, switchMap, takeUntil } from "rxjs";

import { CongratsComponent, CongratsType } from "../../shared/components/congrats/congrats.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { Train } from "../models/train.entity";
import { TrainRepository } from "../repositories/train.repository";

@Component({
  selector: 'app-my-trains',
  templateUrl: './my-trains.component.html',
})
export class MyTrainsComponent implements OnInit, OnDestroy {

  trains: Train[] = [];

  trainsCompleted: Train[] = [];

  availableTrains: Train[] = [];

  loading = true;

  destroy$ = new Subject<void>();

  checkForSherpaAlert$ = new Subject<void>();

  constructor(private readonly profileService: ProfileService,
              private readonly router: Router,
              private readonly route: ActivatedRoute,
              private readonly trainRepository: TrainRepository,
              private readonly dialog: DialogService,
              private readonly titleService: Title,
  ) {
  }

  ngOnInit(): void {
    this.checkForSherpaAlert$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(() => this.checkForSherpaIfTrainWantedIsStillAvailable());
    this.titleService.setTitle('We Talk');


    this.trainRepository.getMyTrains().subscribe(trains => {

      this.trains = trains.filter(train => !train.isCompleted);
      this.trainsCompleted = trains.filter(train => train.isCompleted);

      this.checkForSherpaAlert$.next();
    });


    this.trainRepository.getSherpaAvailableTrains().subscribe(trains => {
      this.availableTrains = trains;
      this.loading = false;
      this.checkForSherpaAlert$.next();
    });


  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  checkForSherpaIfTrainWantedIsStillAvailable(): void {
    const isWantingToManage = !!this.route.snapshot.queryParamMap.get('manage');
    const trainId = this.route.snapshot.queryParamMap.get('trainId');

    if (isWantingToManage && this.profileService.profile?.role === Role.Sherpa && trainId && ![ ...this.availableTrains, ...this.trains ].map(train => train.id).includes(trainId)) {
      this.alertForMissedTrain();
    }
  }

  congratsAfterManageTrain(): Observable<undefined> {
    return this.dialog.open(CongratsComponent, {
      title: `Merci ${ capitalize(this.profileService.profile?.firstName ?? '') } !`,
      message: 'Ta prise en charge du parcours est bien validée !\n\nTu recevras prochainement par email toutes les informations nécessaires à l’animation de ce parcours : nombre de Talkers, confirmation des dates de session, etc.\n\nBon parcours We Talk !',
      type: CongratsType.POST_TRAIN_CONFIRM
    });
  }

  alertForMissedTrain(): Observable<undefined> {
    return this.dialog.open(CongratsComponent, {
      title: `Bonjour ${ capitalize(this.profileService.profile?.firstName ?? '') } !`,
      message: 'Merci pour ta réactivité, malheureusement un autre sherpa a déjà été validé sur ce parcours.\n' +
        '\n' +
        'Pas d’inquiétude, d’autres opportunités s’ouvriront rapidement !\n' +
        '\n' +
        'L’équipe We Talk\n',
      type: CongratsType.MISSED_TRAIN
    });
  }

  manageTrain(trainId: string) {
    this.trainRepository.manageTrain(trainId).pipe(
      switchMap(() => this.congratsAfterManageTrain()),
      catchError(() => this.alertForMissedTrain())).subscribe(() => {
      this.trainRepository.getSherpaAvailableTrains().subscribe(trains => {
        this.availableTrains = trains;

      });

      this.trainRepository.getMyTrains().subscribe(trains => {
        this.trains = trains.filter(t => !t.isCompleted);
        this.trainsCompleted = trains.filter(t => t.isCompleted);
      });
    });
  }

  goToTrain(trainId: string): void {
    this.router.navigate([ 'trains', trainId ]);
  }

}
