<div class="column gap">
    <h2 class="space-between row">{{content.title}}</h2>
    <div class="row gap center-children-vertically">
        <div *ngFor="let pillar of content.pillars">
            <app-icon [type]="getPillarIcon(pillar)" size="25"></app-icon>
        </div>
        <div>Vie professionnelle</div></div>
    <div class="details-wrapper row gap">
        <div class="content-details-main">
            <p class="content-details-description-text">
                {{content.description}}
            </p>
            <div class="row space-between margin-top margin-bottom-xl"> 
                <div class="row center-children-vertically" *ngIf="content.readingDuration">
                    <app-icon size="24" type="clock-circle"></app-icon>
                    <div>{{content.readingDuration}} mn</div>
                </div>
                <div class="row">
                    <app-icon size="24" type="thumb-dislike-inactive"></app-icon>
                    <app-icon size="24" type="thumb-like-inactive"></app-icon>
                </div>
            </div>
            <div class="image-container center-horizontally-by-margin">
                <img [src]="content.image" alt="Description of image">
            </div>
        </div>
        <div class="divider"></div>
        <div class="column column-gap content-details-aside">
            <p class="margin-bottom">
                <strong>Approfondir ce sujet:</strong>
            </p>
            <p>
                Pour aller plus loin et être accompagné par nos Sherpas sur
                ce sujet, nous vous recommandons de rejoindre un parcours sur l'une 
                des thématiques suivantes: 
            </p>
        </div>
    </div>
</div>