import { HttpClient } from "@angular/common/http";
import { ElementRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JitsiMeetExternalAPI } from "jitsi-meet";
import { delay, filter, Observable, Subject } from "rxjs";

import { APP_CONFIG } from "../config/config";
import { AppConfig } from "../config/config.type";
import { ConfirmComponent } from "../shared/components/confirm/confirm.component";
import { DialogService } from "../shared/components/dialog/dialog.service";
import { NavigationService } from "../shared/services/navigation.service";
import { capitalize } from "../shared/tool-functions/capitalize";
import { Train } from "../trains/models/train.entity";
import { TypeformService } from "../typeform/typeform.service";
import { TypeformFormType } from "../typeform/typeform.type";
import { Role } from "../users/models/users.entity";
import { ProfileService } from "../users/services/profile.service";

import { MeetInstance } from "./meet.type";


@Injectable({ providedIn: 'root' })
export class MeetService {
  private readonly apiUrl!: string;

  private readonly jitsiAppId!: string;

  public isMeeting = false;

  private startMeetingDate?: Date;

  private startMeeting$ = new Subject<Train>();

  private endMeeting$ = new Subject<void>();

  private meetingHadNormalDuration$ = new Subject<void>();

  private currentTrain?: Train;

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly http: HttpClient,
              private readonly profileService: ProfileService,
              private readonly router: Router,
              private readonly typeformService: TypeformService,
              private readonly navigationService: NavigationService,
              private readonly dialog: DialogService) {
    this.apiUrl = this.appConfig.apiUrl;
    this.jitsiAppId = this.appConfig.jitsiAppId;
    this.startMeeting$
      .subscribe((train) => {
        this.currentTrain = train;
        this.startMeetingDate = new Date();
      });
    this.endMeeting$
      .subscribe(() => {
        if (this.startMeetingDate && ((new Date()).getTime() - this.startMeetingDate.getTime()) > 10 * 1E3) {
          this.meetingHadNormalDuration$.next();
        } else {
          this.currentTrain = undefined;
        }
        this.startMeetingDate = undefined;
      });

    this.meetingHadNormalDuration$.pipe(
      filter(() => !!this.profileService.profile?.role && this.profileService.profile?.role === Role.Talker),
      delay(1500)
    ).subscribe(() => {
      const train = this.currentTrain;
      if (train) {
        if (!train.isCompleted && train.getNearestSession()) {
          this.dialog.open(ConfirmComponent, {
              title: `${ capitalize(this.profileService.profile?.firstName ?? '') }, qu’avez-vous pensé de cette séance ? ⭐`,
              message: 'En quelques questions,<b> vous pouvez nous partager votre ressenti.</b>\n' +
                'Pourquoi ?\n' +
                '💭 Vous prenez un <b>instant de recul</b> sur ce que cette séance vous a apporté, vous <b>ancrez vos apprentissages</b> et <b>préparez la suite</b> du parcours.\n' +
                '💜 Grâce à vos feedbacks, vous nous permettez d’<b>améliorer notre accompagnement, pour le bénéfice de toutes et tous.</b>\n',
              cancelMessage: 'Plus tard',
              confirmMessage: "J'évalue ma séance en 3 minutes",
              reverseColor: true
            }
          )

            .subscribe(isValidated => {
              const nearestSession = train.getNearestSession();
              if (isValidated && nearestSession) {
                this.typeformService.goToSpecificForm({
                  type: TypeformFormType.POST_SESSION,
                  session: nearestSession
                });
              }
              this.currentTrain = undefined;
            });
        }
        if (train.isCompleted && train.getNearestSession()) {
          this.dialog.open(ConfirmComponent, {
            title: `Vous venez de compléter le parcours ! Bravo ${ capitalize(this.profileService.profile?.firstName ?? '') } ! 🎉`,
            message: 'Pour finir, et si vous preniez quelques minutes pour nous dire ce que vous en avez pensé, et <b>faire le point sur votre évolution ?</b>\n' +
              'En outre, votre retour nous aidera à mieux apprécier l’efficacité de la solution We Talk sur les différentes dimensions du bien-être.\n',
            cancelMessage: 'Plus tard',
            confirmMessage: "Je fais le bilan",
            reverseColor: true
          })
            .subscribe(isValidated => {
              if (isValidated) {
                this.typeformService.goToSpecificForm({ type: TypeformFormType.POST_TRAIN, train });
              }
              this.currentTrain = undefined;
            });
        }
      }

    });

    this.router.events
      .subscribe(() => {
        if (this.isMeeting) {
          this.endMeeting$.next();
        }
        this.isMeeting = false;
      });
  }

  getTrainToken(trainId: string): Observable<{ token: string }> {
    return this.http.get<{ token: string }>([ this.apiUrl, 'meet', trainId, 'my-token' ].join('/'));
  }

  joinMeet(train: Train) {
    this.router.navigate([ 'meet', train.id ]);
  }

  startSession(train: Train) {
    this.startMeeting$.next(train);
  }

  closeSession() {
    this.endMeeting$.next();
    this.router.navigate(this.navigationService.lastPage$.getValue().split('/'));
  }

  getSessionStart$() {
    return this.startMeeting$.asObservable();
  }


  getSessionEnd$() {
    return this.endMeeting$.asObservable();
  }

  getSessionHasNormalDuration$() {
    return this.meetingHadNormalDuration$.asObservable();
  }


  getInstance(token: string, elementRef: ElementRef<HTMLElement>, train: Train): MeetInstance {
    const lang = this.profileService.profile?.lang?.name;

    this.isMeeting = true;

    // @ts-ignore
    const instance = new JitsiMeetExternalAPI(this.appConfig.jitsiDomain, {
      roomName: `${ this.jitsiAppId }/test/${ train.id }`,
      parentNode: elementRef.nativeElement,
      lang,
      jwt: token,
      configOverwrite: {
        disableModeratorIndicator: true,
        subject: train ? `Séance ${ Math.min(train.currentSessionIndex, train.sessions.length - 1) + 1 }` : 'Test',
        toolbarButtons: [ 'chat', 'hangup', 'microphone', 'camera', 'desktop', 'select-background', 'tileview', 'raisehand' ],
      },
    }) as MeetInstance;

    instance.addListener('readyOnClose', () => {
      this.isMeeting = false;
    });

    return instance;
  }
}
