export enum TrackerCategory {
    MYSPACE_TALKER = 'mySpaceTalker',
    HOME_TALKER = 'homeTalker',
    THEMATIC_PAGE_TALKER = 'thematicPageTalker',
    THEMATICS_TALKER = 'thematicsTalker',
    TRAINS_TALKER = 'trainsTalker',
    MENU_TALKER = 'menuTalker',
    CREDITS_PILOT = 'creditsPilot',
    STATS_PILOT = 'statsPilot',
    SETTINGS_PILOT = 'settingsPilot',
    MANAGE_THEMATICS_PAGE = 'manageThematicsPage',
    LOGIN_PAGE = 'loginPage',
    REGISTER_PAGE = 'registerPage',  
}

export enum TrackerAction {
    CLICK = 'Click',
}