<div class="column gap" #page [class.fade-left]="fadeLeft$ | async"
     [class.fade-left-reverse]="fadeLeftReverse$ | async">
  <app-thematic-card [big]="true" [withoutTitle]="true" [thematic]="thematic"></app-thematic-card>

  <div class="space-between row">
    <h1 class="cooper title">{{thematic.title}}</h1>
    <app-favorite-thematic [thematic]="thematic"/>
  </div>
  <p><strong>{{thematic.resume}}</strong></p>

  <div class="section row gap center-children-vertically pointer"  >
    <app-profile-icon (click)="goToSherpa()" *ngIf="train.sherpa" [profile]="train.sherpa" [tiny]="true" [editable]="false"></app-profile-icon>
    <div (click)="goToSherpa()" *ngIf="train.sherpa">
      <strong>{{ train.dynamic.dynamicType}}</strong> <span *ngIf="train.sherpa">  avec <u>{{train.sherpa.fullName}}</u></span>
    </div>
    <app-button *ngxPermissionsOnly="'admin'" (onClick)="modifySherpa()">Sélectionner le sherpa</app-button>
  </div>


  <h2 class="section">Les séances</h2>
  <div class="wrap">
    <div *ngFor="let session of train.sessions; let index = index;" [class.success]="index < train.currentSessionIndex" [class.current]="index === train.currentSessionIndex" class="bubble column center-children"
        [class.pointer]="isSherpaOrAdminWatching"
         (click)="modifySessionDate(index)">
      <strong class="tiny-margin-bottom">{{ index + 1}}{{index === 0 ? 'r' : ''}}e séance</strong>
      <p>{{formatDate(session.date)}}</p>
      <span>à {{formatTime(session.date, 'h')}}</span>
      <div class="color-white icon center-children" [class.success]="index < train.currentSessionIndex" [class.current]="index === train.currentSessionIndex">
        <app-icon class="color-white"  [type]="index < train.currentSessionIndex ? 'check' : 'clock'" />
      </div>
    </div>
  </div>

  <h2 class="section">Participants</h2>
  <div class="wrap">
    <div *ngFor="let membership of train.memberShips" class="bubble column center-children"
         [class.pointer]="isSherpaOrAdminWatching" (click)="goToTalkerDetails(membership.user.id)">
      {{ membership.user.id === userId ? 'Vous' : membership.user.title}}
    </div>
  </div>

  <h2 class="section">Dans quelle dynamique ?</h2>
  <app-dynamics class="dynamics" [dynamics]="[train.dynamic]" [canValidate]="false"/>


  <div *ngIf="thematic.testimonials?.length">
    <h2 class="margin-bottom-xl margin-top">Témoignages</h2>
    <app-slider *ngIf="thematic.testimonials" [items]="thematic.testimonials">
      <ng-template #slide let-item>
        <div class="testimonial" [class]="categorySlug">
          <div class="testimonial-content">
            <p class="margin-bottom">{{item.description}}</p>
            <p><strong>{{item.author}}</strong></p>
          </div>
        </div>
      </ng-template>
    </app-slider>
  </div>


  <div *ngIf="thematic.keyNumbers?.length">
    <h2>Des chiffres qui parlent</h2>
    <div class="wrap gap">
      <div class="column margin-right" *ngFor="let keyNumber of thematic.keyNumbers">
        <p class="key-number cooper text-xl" [class]="categorySlug">{{keyNumber.stringNumber}}</p>
        <div class="key-number-description">{{keyNumber.description}}</div>
      </div>
    </div>
  </div>

  <div class="section" *ngxPermissionsExcept="['admin']">
    <a class="leave-button" title="Quitter le parcours" *ngIf="canLeave"
                (click)="leaveTrain()">Quitter le parcours en cours
    </a>
  </div>
</div>
