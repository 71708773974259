import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { combineLatestWith, filter, Subject, takeUntil } from "rxjs";

import { capitalize } from "../shared/tool-functions/capitalize";
import { TrackerAction, TrackerCategory } from '../shared/types/tracker';
import { mediumCategoryColors } from "../thematics/colors";
import { ThematicCategory } from "../thematics/models/category.model";
import { Thematic } from "../thematics/models/thematic.model";
import { ThematicRepository } from "../thematics/repositories/thematic.repository";
import { Train } from "../trains/models/train.entity";
import { TrainRepository } from "../trains/repositories/train.repository";
import { Talker } from "../users/models/talker.entity";
import { Role } from "../users/models/users.entity";
import { ProfileService } from "../users/services/profile.service";


@Component({
  selector: 'app-my-space',
  templateUrl: './my-space.component.html'
})
export class MySpaceComponent implements OnInit, OnDestroy {

  loading = true;

  destroy$ = new Subject<void>();

  currentTrains: Train[] = [];

  overTrains: Train[] = [];

  sessionsDone!: number;

  hoursDone!: number;

  favoriteThematics: Thematic[] = [];

  favoriteCategories: ThematicCategory[] = [];

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly router: Router,
              private readonly profileService: ProfileService,
              private readonly thematicRepository: ThematicRepository,
              private readonly trainRepository: TrainRepository) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {

    this.profileService.getMyProfile()
      .pipe(
        filter((profile): profile is Talker => profile.role === Role.Talker),
        combineLatestWith(this.trainRepository.getMyTrains(), this.thematicRepository.getAvailableOnes(), this.thematicRepository.getCategories()),
        takeUntil(this.destroy$))
      .subscribe(([ profile, trains, thematics, categories ]) => {
        this.currentTrains = trains.filter(train => !train.isCompleted);
        this.overTrains = trains.filter(train => train.isCompleted);

        this.favoriteThematics = thematics
          .filter(thematic => (profile.favoriteThematics
            ?.map(ft => ft.id) ?? [])
            .includes(thematic.id));
        this.favoriteCategories = categories
          .filter(category => this.favoriteThematics
            .map(t => t.category?.id)
            .includes(category.id));

        this.sessionsDone = trains.reduce((sum, train) => sum + train.currentSessionIndex, 0);
        this.hoursDone = Math.floor(trains.reduce((sum, train) => sum + (train.currentSessionIndex * train.sessionDuration), 0) / (1E3 * 3600));

        this.loading = false;
      });
  }

  goToThematics() {
    this.tracker.trackEvent(TrackerCategory.MYSPACE_TALKER, TrackerAction.CLICK, 'thematicsButton');
    this.router.navigate([ 'thematics' ]);
  }

  goToAvailableTrains() {
    this.tracker.trackEvent(TrackerCategory.MYSPACE_TALKER, TrackerAction.CLICK, 'trainsButton');
    this.router.navigate([ 'available-trains' ]);
  }

  clickOnThematic(slug: string) {
    this.router.navigate([ 'thematics', slug ]);
  }

  goToTrain(train: Train) {
    this.router.navigate([ 'trains', train.id ]);
  }

  goToMeeting(trainId: string): void {
    this.router.navigate([ 'meet', trainId ]);
  }

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly capitalize = capitalize;
}
