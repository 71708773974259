import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { combineLatestWith, Observable, of, Subject, takeUntil } from "rxjs";
import { map } from "rxjs/operators";

import { ConfirmComponent } from "../../shared/components/confirm/confirm.component";
import { DialogService } from "../../shared/components/dialog/dialog.service";
import { CanComponentDeactivate } from "../../shared/guard/candeactivate.guard";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { stringNumber } from "../../shared/tool-functions/string-number";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Gender } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { TypeformService } from "../typeform.service";
import { TypeformFormType, TypeformHiddenFields } from "../typeform.type";


@Component({
  selector: 'app-typeform-page',
  templateUrl: './typeform-page.component.html',
  styleUrls: [ './typeform-page.component.scss' ]
})
export class TypeformPageComponent implements AfterViewInit, OnDestroy, CanComponentDeactivate {

  public type?: TypeformFormType;

  public hiddenFields!: TypeformHiddenFields;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly route: ActivatedRoute,
              private readonly typeformService: TypeformService,
              private readonly profileService: ProfileService,
              private readonly changeDetector: ChangeDetectorRef,
              private readonly thematicRepository: ThematicRepository,
              private readonly dialog: DialogService) {

  }

  ngAfterViewInit(): void {
    this.route.paramMap
      .pipe(
        combineLatestWith(this.profileService.getMyProfile(), this.thematicRepository.getAvailableOnes()),
        takeUntil(this.destroy$))
      .subscribe(([ params, profile, thematics ]) => {
        this.type = params.get('type') as TypeformFormType ?? TypeformFormType.INITIAL;
        const id = params.get('id') ?? undefined;

        let lastDate: Date | undefined;
        let thematicName: string | undefined;

        if (id !== undefined &&
          (this.type === TypeformFormType.POST_TRAIN
            || this.type === TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN)
        ) {
          const train = this.typeformService.getFormTrain(this.type, id);
          lastDate = train?.getLastSession()?.date;
          thematicName = thematics.find(t => t.dynamics.some(d => d.id === train?.dynamic?.id))?.name;
        }
        if (id !== undefined && this.type === TypeformFormType.POST_SESSION) {
          const sessionAndTrain = this.typeformService.getFormSessionAndTrain(id);
          if (sessionAndTrain) {
            const { session, train } = sessionAndTrain;
            thematicName = thematics.find(t => t.dynamics.some(d => d.id === train?.dynamic?.id))?.name;
            lastDate = session?.date;
          }
        }

        this.hiddenFields = {
          id_user: profile.id,
          id_parcours: id,
          date_latest_session: `${ stringNumber(lastDate?.getDate() ?? 0) }/${ stringNumber((lastDate?.getMonth() ?? 0) + 1) } ${ stringNumber(lastDate?.getHours() ?? 0) }h${ stringNumber(lastDate?.getMinutes() ?? 0) }`,
          source: 'platform',
          theme_title_fr: capitalize(thematicName ?? '')
        };
        this.changeDetector.detectChanges();

        this.typeformService.startResponding();
      });
  }

  ngOnDestroy() {
    this.typeformService.stopResponding();
    this.destroy$.next();
  }

  @HostListener('window:beforeunload')
  canLeavePage() {
    return false;
  }

  canDeactivate(): Observable<boolean> {
    if (this.typeformService.needConfirmToQuit) {
      return this.dialog.open(ConfirmComponent, {
        title: `Êtes-vous sûr${ this.profileService.profile?.gender === Gender.WOMAN ? 'e' : '' } de vouloir quitter le questionnaire ?`,
      }).pipe(map(isConfirm => isConfirm !== false));
    }

    return of(true);

  }
}

