<div class="column" [class.fade-right]="fadeRight$ | async" [class.fade-right-reverse]="fadeRightReverse$ | async">
  <div class="row gap">
    <app-image class="image" [height]="50" [width]="100" [src]="thematic.imageUrl"
                   [alt]="thematic.id" />
    <div class="column no-gap">
      <p>PARCOURS</p>
      <h3 class="cooper">{{thematic.title}}</h3>
    </div>
  </div>
  <div class="full-width">
    <router-outlet></router-outlet>
  </div>
</div>
