import { Component, Input } from "@angular/core";

import { Organization } from "../../../../organizations/models/organizations.entity";
import { DialogService } from "../../dialog/dialog.service";

@Component({
  selector: "app-organization-card",
  templateUrl: "./organization-card.component.html",
})
export class OrganizationCardComponent {
  @Input() organization!: Organization;

  @Input() loading = false;

  constructor(private readonly dialog: DialogService) {}

  get parentDescription(): string {
    return this.organization.parent
      ? ` - ${this.organization.parent.name}`
      : "";
  }

  get organizationInfos(): string {
    return `${this.organization.members?.filter(o => !!o.personalInformation)?.length ?? 0} collaborateurs 
    | ${this.organization.units} unités restantes 
    | ${
      this.organization.members?.reduce(
        (sum, user) => sum + (user?.trainMemberships?.length ?? 0),
        0
      ) ?? 0
    } parcours`;
  }
}
