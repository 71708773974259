import { Component, Inject, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { Subject, takeUntil } from "rxjs";

import { APP_CONFIG } from "../../../../config/config";
import { AppConfig } from "../../../../config/config.type";
import { OrganizationRepository } from "../../../../organizations/repositories/organization.repository";
import { lightCategoryColors, mediumCategoryColors } from "../../../../thematics/colors";
import { CreateTrainService } from "../../../../thematics/create-train.service";
import { Train } from "../../../../trains/models/train.entity";
import { Sherpa } from "../../../../users/models/sherpa.entity";
import { formatDate, formatTimeSlot } from "../../../tool-functions/date-tools";
import { TrackerAction, TrackerCategory } from "../../../types/tracker";
import { SnackbarService } from "../../snackbar/snackbar.service";

@Component({
  selector: 'app-available-train',
  templateUrl: './available-train.component.html',
  styleUrls: [ './available-train.component.scss' ]
})
export class AvailableTrainComponent implements OnInit, OnDestroy {

  @Input() train!: Train;

  colorMedium!: string;

  colorLight!: string;

  destroys$ = new Subject<void>();

  private readonly tracker = inject(MatomoTracker);

  constructor(@Inject(APP_CONFIG)
              private readonly appConfig: AppConfig,
              private readonly router: Router,
              private readonly creatTrainService: CreateTrainService,
              private readonly snackBarService: SnackbarService,
              private readonly organizationRepository: OrganizationRepository) {
  }

  ngOnInit() {
    this.colorMedium = this.train.dynamic.thematic.category?.slug ? mediumCategoryColors[this.train.dynamic.thematic.category.slug] : '';
    this.colorLight = this.train.dynamic.thematic.category?.slug ? lightCategoryColors[this.train.dynamic.thematic.category.slug] : '';
  }

  ngOnDestroy() {
    this.destroys$.next();
  }

  dateFormat = formatDate;

  timeFormat = formatTimeSlot;

  goToSherpa(sherpa: Sherpa): void {
    this.router.navigate([ 'sherpas', sherpa.id ]);
  }

  selectTrain() {
    this.tracker.trackEvent(TrackerCategory.TRAINS_TALKER, TrackerAction.CLICK, 'nextTrainSignup');
    this.organizationRepository.canConsumeUnit()
      .pipe(takeUntil(this.destroys$))
      .subscribe(can => {
        if (can.unitAvailable && can.quota) {
          this.creatTrainService.selectTrain(this.train);
        } else {
          this.snackBarService.pushMessage(!can.unitAvailable ? "Votre entreprise n'a plus d'unité disponible" : "Vous avez consommé le quota d'unités qui vous a été accordé par votre entreprise.", "error");
        }
      });
  }

}
