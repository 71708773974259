import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from '@angular/router';
import { MatomoTracker } from 'ngx-matomo-client';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";

import { FeedbackStats } from '../feedbacks/models/feedbacks.entity';
import { FeedbacksRepository } from '../feedbacks/repositories/feedbacks.repository';
import { Organization, OrganizationTrainMembership } from "../organizations/models/organizations.entity";
import { OrganizationRepository } from "../organizations/repositories/organization.repository";
import { OrganizationStoreService } from '../organizations/services/organization.store.service';
import { flattenOrganizationTrees } from "../organizations/utils/utils";
import { BreadcrumbInput } from '../shared/components/breadcrumb/breadcrumb.component';
import { Checkbox } from "../shared/components/checkmarks/checkmarks.component";
import { IconType } from '../shared/components/icon/icon.component';
import { InPageTab } from "../shared/components/in-page-tabs/in-page-tabs/in-page-tabs.component";
import { TreeCheckbox } from "../shared/components/tree-checkmarks/tree-checkmarks.component";
import { CsvService } from '../shared/services/csv.service';
import { capitalize } from "../shared/tool-functions/capitalize";
import { getEmojiColorByType } from '../shared/tool-functions/emojis';
import { EntityFormGroup } from "../shared/types/entity-form-group";
import { TrackerAction, TrackerCategory } from '../shared/types/tracker';
import { mediumCategoryColors } from "../thematics/colors";
import { DynamicType } from "../thematics/models/dynamic.model";
import { Thematic } from "../thematics/models/thematic.model";
import { ThematicRepository } from "../thematics/repositories/thematic.repository";
import { TrainMembership } from "../trains/models/membership.entity";
import { Train } from "../trains/models/train.entity";
import { RESPONSE_DATA, ResponseResultType } from "../typeform/response/response.data";
import {
  PostTrainFormResponse,
  TypeformFormType,
  TypeformResponseFromApi,
  TypeformResponseWithPageResult
} from "../typeform/typeform.type";
import { Sherpa } from "../users/models/sherpa.entity";
import { Talker } from "../users/models/talker.entity";
import { Role } from "../users/models/users.entity";
import { Profile, ProfileService } from "../users/services/profile.service";


import { TypeformScoreBarChartData } from "./charts/typeform-score-bar-chart/typeform-score-bar-chart.component";
import { DashboardRepository } from "./dashboard.repository";
import { DateInterval } from "./utils/bar-chart-tools";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('wrapper') wrapperContainer!: ElementRef<HTMLElement>;

  @ViewChild('talkerSection') talkerSection!: ElementRef<HTMLElement>;

  @ViewChild('commitmentSection') commitmentSection!: ElementRef<HTMLElement>;

  @ViewChild('satisfactionSection') satisfactionSection!: ElementRef<HTMLElement>;

  @ViewChild('impactSection') impactSection!: ElementRef<HTMLElement>;

  destroy$ = new Subject<void>();

  reload$ = new Subject<void>();

  loading = true;

  canSeeStatistics = false;

  organizations: Organization[] = [];

  selectedOrganizations$: BehaviorSubject<Organization[]> = new BehaviorSubject<Organization[]>([]);

  selectedInterval$: BehaviorSubject<DateInterval> = new BehaviorSubject<DateInterval>({
    fromDate: new Date(),
    toDate: new Date()
  });

  organizationTrees!: TreeCheckbox[];

  thematics$: BehaviorSubject<Thematic[]> = new BehaviorSubject<Thematic[]>([]);


  thematics: Thematic[] = [];

  thematicCheckboxes: Checkbox[] = [];

  thematicCheckboxesSelected$: BehaviorSubject<Checkbox[]> = new BehaviorSubject<Checkbox[]>([]);

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [
    {
      id: DynamicType.COACHING,
      key: "Coaching en groupe",
      selected: false,
    },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  dynamicTypeCheckboxesSelected$: BehaviorSubject<Checkbox<DynamicType>[]> = new BehaviorSubject<Checkbox<DynamicType>[]>([]);

  dateForm!: EntityFormGroup<{ fromDate: string, toDate: string }>;

  invitedTalkers!: Talker[];

  activatedTalkers!: Talker[];

  talkersWithTrain!: Talker[];

  activatedInvitedPercent!: number;

  talkerInvitedPercent!: number;

  talkerActivatedPercent!: number;

  allTalkers: Talker[] = [];

  allTypeformResponses: TypeformResponseFromApi[] = [];

  talkers$: BehaviorSubject<Talker[]> = new BehaviorSubject<Talker[]>([]);

  allOrganizationTrainMemberships: OrganizationTrainMembership[] = [];

  allTrainMemberships: TrainMembership[] = [];

  trains: Train[] = [];

  firstTrainMemberships$ = new BehaviorSubject<TrainMembership[]>([]);

  trainMemberships$ = new BehaviorSubject<TrainMembership[]>([]);

  sherpas: Sherpa[] = [];

  showSelection = false;

  isAdminWatching = false;

  isSherpaWatching = false;

  sessionsDone: number = 0;

  trainsDone = 0;

  trainsInProgress = 0;

  hoursDone: number = 0;

  sherpasInvolved: number = 0;

  coachingSherpasInvolved: number = 0;

  speechSherpasInvolved: number = 0;

  canSeePostTrainData = false;

  goalsAchievements: { label: string, responses: number, percent: number, color: string }[] = [
    {
      label: "3 - Je les ai totalement atteints voire dépassés",
      responses: 0,
      percent: 0,
      color: '#239692'
    },
    {
      label: "2 - J’ai atteint la plupart de mes objectifs",
      responses: 0,
      percent: 0,
      color: '#2CBBB6'
    }, {
      label: "1 - Je les ai atteints en partie",
      responses: 0,
      percent: 0,
      color: '#7BCDCC'
    }, {
      label: "0 - Je n’ai pas du tout atteint mes objectifs",
      responses: 0,
      percent: 0,
      color: '#B4DDDB'
    },
  ];

  thematicProgress: { responses: number, percent: number, marginTop: string }[] = [];

  typeformResultDescriptions = [ "Avant le parcours", "Après le parcours", "6 mois après le parcours" ];

  diagnosticScores: TypeformScoreBarChartData[] = [];

  diagnosticScoresData: Record<ResponseResultType, Omit<TypeformScoreBarChartData, 'steps'> & {
    colors: [ string, string, string ]
  }> = {
    omsMood: {
      title: 'Satisfaction de vie',
      colors: [ "#FFA135", "#FF8B05", "#CC6F04" ],
      color: '#FF8B05',
      icon: 'heart',

    },
    flourishing: {
      title: 'Affects',
      colors: [ "#BA9FD0", "#8C5FB0", "#704C8D" ],
      color: '#8C5FB0',
      icon: 'smile'
    },
    workSatisfaction: {
      title: 'Épanouissement au travail',
      colors: [ "#7CAAD5", "#2D7DC8", "#2464A0" ],
      color: '#2D7DC8',
      icon: 'suit-case'
    }
  };

  sherpaSatisfactionPercent: number = 0;

  sessionSatisfactionPercent: number = 0;

  relativesRecommendationPercent: number = 0;

  wantToDoAnotherTrainPercent: number = 0;

  coveredOrganizationsForSherpa: Organization[] = [];

  userCreationDate!: Date;

  feedbackStats?: FeedbackStats;

  talkerOrganization?: Organization;

  profile?: Profile;

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly organizationRepository: OrganizationRepository,
              private readonly dashboardRepository: DashboardRepository,
              private readonly profileService: ProfileService,
              private readonly formBuilder: FormBuilder,
              private readonly thematicRepository: ThematicRepository,
              private readonly feedbacksRepository: FeedbacksRepository,
              private readonly csvService: CsvService,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly route: ActivatedRoute) {
    this.dateForm = this.formBuilder.group({
      fromDate: new FormControl('', { validators: Validators.required, nonNullable: true }),
      toDate: new FormControl('', { validators: Validators.required, nonNullable: true })
    });
  }

  ngOnInit() {
    combineLatest([
      this.dashboardRepository.getMembersData(),
      this.dashboardRepository.getTypeformData(),
      this.dashboardRepository.getTrainsData(),
      this.profileService.getMyProfile(),
      this.thematicRepository.getAll(),
      this.organizationStoreService.getObservable()
    ])
      .pipe(
        tap(([ members, typeformResponses, organizationTrainMemberships, profile, thematics, talkerOrganization ]) => {
          this.allTalkers = members;
          this.allTypeformResponses = typeformResponses;
          this.allOrganizationTrainMemberships = organizationTrainMemberships;
          this.allTrainMemberships = organizationTrainMemberships.flatMap(o => o.trainMemberships);
          this.isAdminWatching = profile.role === Role.Admin;
          this.isSherpaWatching = profile.role === Role.Sherpa;
          this.userCreationDate = profile.createdAt;
          this.profile = profile;
          this.thematics$.next(thematics);
          this.thematicCheckboxes = thematics.map(thematic => ({
            id: thematic.id,
            key: thematic.name,
            selected: false
          }));
          this.coveredOrganizationsForSherpa = members.reduce((acc, member) => {
            const currentIds = acc.map(o => o.id);
            if (!currentIds.includes(member.organization.id)) {
              acc.push(member.organization);
            }
            return acc;
          }, [] as Organization[]);
          this.talkerOrganization = talkerOrganization;
        }),
        switchMap(() => this.selectedOrganizations$),
        combineLatestWith(this.dateForm.valueChanges.pipe(startWith(this.dateForm.value)), this.thematicCheckboxesSelected$.pipe(startWith([])), this.dynamicTypeCheckboxesSelected$.pipe(startWith([]))),
        takeUntil(this.destroy$),
      )
      .subscribe(([ organizationsFromObservable, intervalSelected, thematicCheckboxes, dynamicCheckboxes ]) => {
        const organizations = this.isSherpaWatching ? this.coveredOrganizationsForSherpa : organizationsFromObservable;
        const selectedDynamicTypes: DynamicType[] = dynamicCheckboxes.map(c => c.id);
        const selectedThematics: string[] = thematicCheckboxes.map(c => c.id);


        const selectedOrganizationIds = (this.isSherpaWatching ? this.coveredOrganizationsForSherpa : organizations).map(o => o.id)
          .filter(id => {
            if (!this.isAdminWatching) {
              return this.allTalkers.filter(t => t.organization.id === id && !!t.activatedAt).length >= 5 && this.allOrganizationTrainMemberships
                .filter(t => t.id === id)
                .reduce((acc, organizationTrainMembership) => acc + organizationTrainMembership.firstTrainMemberships.length, 0) >= 5;
            }
            return true;
          });
        this.canSeeStatistics = selectedOrganizationIds.length > 0 || this.isSherpaWatching;
        const interval: DateInterval = this.isSherpaWatching ? {
          fromDate: this.userCreationDate,
          toDate: new Date()
        } : {
          fromDate: intervalSelected.fromDate ? new Date(intervalSelected.fromDate) : undefined
            ?? new Date('2020-01-01'),
          toDate: intervalSelected.toDate ? new Date(intervalSelected.toDate) : new Date()
        };

        this.selectedInterval$.next(interval);

        this.talkers$.next(this.allTalkers.filter(talker =>
          (this.isSherpaWatching || (selectedOrganizationIds.includes(talker.organization.id))
            && talker.createdAt.getTime() >= interval.fromDate.getTime()
            && talker.createdAt.getTime() < interval.toDate.getTime())
        ));

        const selectedTalkerIds = this.talkers$.getValue().map(talker => talker.id);


        this.trains = this.allOrganizationTrainMemberships
          .filter(organizationTrainMemberships => this.isSherpaWatching || selectedOrganizationIds.includes(organizationTrainMemberships.id))
          .flatMap(organizationTrainMemberships => organizationTrainMemberships.trainMemberships.map(trainMembership => trainMembership.train))
          .filter(train => (selectedDynamicTypes.length === 0 || selectedDynamicTypes.includes(train.dynamic.type)) && (selectedThematics.length === 0 || selectedThematics.includes(train.dynamic.thematic.id)))
          .filter(train => train && train.getFirstSession().date.getTime() > interval.fromDate.getTime()
            || (train && train.getLastSession().date.getTime() > interval.fromDate.getTime()))
          .reduce((acc, train) => {
            if (!acc.map(t => t.id).includes(train.id)) {
              acc.push(train);
            }
            return acc;
          }, [] as Train[]);


        const selectedTrainIds = this.trains.map(train => train.id);

        this.trainsDone = this.trains.filter(t => t.isCompleted).length;
        this.trainsInProgress = this.trains.filter(t => !t.isCompleted).length;

        this.trainMemberships$.next(this.allOrganizationTrainMemberships
          .filter(organizationTrainMemberships => this.isSherpaWatching || selectedOrganizationIds.includes(organizationTrainMemberships.id))
          .flatMap(organizationTrainMemberships => organizationTrainMemberships.trainMemberships)
          .filter(trainMembership => trainMembership && trainMembership.createdAt.getTime() > interval.fromDate.getTime())
          .filter(trainMembership => (selectedDynamicTypes.length === 0 || selectedDynamicTypes.includes(trainMembership.train.dynamic.type)) && (selectedThematics.length === 0 || selectedThematics.includes(trainMembership.train.dynamic.thematic.id)))
          .filter(trainMembership => trainMembership.train && (trainMembership.train.getNextSession()?.date?.getTime() ?? 0) > interval.fromDate.getTime()
            || (trainMembership.train && trainMembership.train.getLastSession().date.getTime() > interval.fromDate.getTime())));

        this.firstTrainMemberships$.next(this.allOrganizationTrainMemberships
          .filter(organizationTrainMemberships => selectedOrganizationIds.includes(organizationTrainMemberships.id))
          .flatMap(organizationTrainMemberships => organizationTrainMemberships.firstTrainMemberships)
          .filter(trainMembership => trainMembership && trainMembership.createdAt.getTime() > interval.fromDate.getTime()));

        const typeformResponses = this.allTypeformResponses
          .map(r => r.data)
          .filter((response): response is TypeformResponseWithPageResult => !!response)
          .filter((response) => response.createdAt.getTime() >= interval.fromDate.getTime() && response.createdAt.getTime() < interval.toDate.getTime())
          .filter((response) => selectedTalkerIds.includes(response.talkerId))
          .filter((response) => response.type === TypeformFormType.INITIAL || selectedTrainIds.includes(response.trainId));

        const postTrainResponses = typeformResponses
          .filter((r): r is PostTrainFormResponse => r.type === TypeformFormType.POST_TRAIN)
          .filter(response => selectedTrainIds.includes(response.trainId));

        this.canSeePostTrainData = !!postTrainResponses.length;

        this.goalsAchievements.forEach((goalStep, step,) => {
          const count = postTrainResponses
            .filter(response => response.goalsAchievement === this.goalsAchievements.length - step - 1)
            .length;
          goalStep.responses = count;
          goalStep.percent = Math.round(count / Math.max(postTrainResponses.length, 1) * 100);
        });



        this.thematicProgress = Array.from(Array(11))
          .map((_, index) => {

              const responses = postTrainResponses
                .filter(response => response.thematicProgress === index)
                .length;
              const percent = Math.round(responses / Math.max(postTrainResponses.length, 1) * 100);

              return {
                responses,
                percent,
                marginTop: `${ 100 - percent }px`
              };
            }
          );

        this.diagnosticScores = ([ 'omsMood', 'flourishing', 'workSatisfaction' ] as ResponseResultType[]).map(scoreType => ({
          ...this.diagnosticScoresData[scoreType],
          steps: [ TypeformFormType.INITIAL, TypeformFormType.POST_TRAIN, TypeformFormType.SIX_MONTHS_POST_FIRST_TRAIN ].map((type, index) => {
            const responses = typeformResponses.filter(response => response.type === type && response[scoreType]);
            const percent = Math.round(responses.reduce((sum, response) => sum + response[scoreType] / RESPONSE_DATA[`${ scoreType }Data`].maximum, 0) * 100 / Math.max(responses.length, 1));

            return {
              responses: responses.length,
              percent,
              marginTop: `${ 300 - percent * 3 }px`,
              color: this.diagnosticScoresData[scoreType].colors[index],
              description: this.typeformResultDescriptions[index]
            };
          })
        }));


        this.relativesRecommendationPercent = Math.round(postTrainResponses.reduce((sum, response) => sum + response.relativesRecommendation, 0) / Math.max(postTrainResponses.length, 1) * 10);
        this.sherpaSatisfactionPercent = Math.round(postTrainResponses.reduce((sum, response) => sum + response.sherpaSatisfaction, 0) / Math.max(postTrainResponses.length, 1) * 10);
        this.sessionSatisfactionPercent = Math.round(postTrainResponses.reduce((sum, response) => sum + response.trainSatisfaction, 0) / Math.max(postTrainResponses.length, 1) * 10);
        this.wantToDoAnotherTrainPercent = Math.round(postTrainResponses.reduce((sum, response) => sum + +response.wantToDoAnotherTrain, 0) / Math.max(postTrainResponses.length, 1) * 100);

        if(selectedOrganizationIds.length){
          this.feedbacksRepository.getFeedBackStats(selectedOrganizationIds).pipe(takeUntil(this.destroy$)).subscribe((feedbackStats) => {
            this.feedbackStats = feedbackStats;
          });
        } 

        this.reload$.next();

        this.loading = false;
      });


    this.getOrganizationObservable()
      .subscribe({
        next: (organizations: Organization[]) => {
          this.organizations = organizations;
          // NOT IN MEP
          // this.organizations = this.talkerOrganization ? [this.talkerOrganization] : organizations;
          this.showSelection = this.organizations?.length > 1;
          this.organizationTrees = this.organizations.map(organization => this.createTree(organization));
          this.selectedOrganizations$.next(flattenOrganizationTrees(this.organizations));
        }
      });

    this.talkers$.pipe(takeUntil(this.destroy$))
      .subscribe(filteredTalkers => {
        this.invitedTalkers = filteredTalkers;
        this.activatedTalkers = filteredTalkers.filter(talker => talker.activatedAt);
        this.talkersWithTrain = filteredTalkers.filter(talker => talker.trainMemberships?.length);
        this.activatedInvitedPercent = Math.round(this.activatedTalkers.length / Math.max(this.invitedTalkers.length, 1) * 100);
        this.talkerActivatedPercent = Math.round(this.talkersWithTrain.length / Math.max(this.activatedTalkers.length, 1) * 100);
        this.talkerInvitedPercent = Math.round(this.talkersWithTrain.length / Math.max(this.invitedTalkers.length, 1) * 100);

      });

    this.trainMemberships$
      .pipe(takeUntil(this.destroy$))
      .subscribe(memberships => {
        this.sessionsDone = memberships.reduce((sum, m) => sum + m.train.currentSessionIndex, 0);
        this.hoursDone = memberships.reduce((sum, m) => sum + Math.floor(m.train.currentSessionIndex * m.train.sessionDuration / 3600E3), 0);

        const sherpaIdList: string[] = [];
        const coachingSherpaIdList: string[] = [];
        const speechSherpaIdList: string[] = [];
        for (const memberShip of memberships) {
          const { sherpaId } = memberShip.train;
          const dynamicType = memberShip.train.dynamic.type;

          if (sherpaId && !sherpaIdList.includes(sherpaId)) {
            sherpaIdList.push(sherpaId);
          }

          if (sherpaId && !coachingSherpaIdList.includes(sherpaId) && dynamicType === DynamicType.COACHING) {
            coachingSherpaIdList.push(sherpaId);
          }

          if (sherpaId && !speechSherpaIdList.includes(sherpaId) && dynamicType === DynamicType.SPEECH) {
            speechSherpaIdList.push(sherpaId);
          }
        }

        this.sherpasInvolved = sherpaIdList.length;
        this.coachingSherpasInvolved = coachingSherpaIdList.length;
        this.speechSherpasInvolved = speechSherpaIdList.length;
      });
  }

  private createTree(organization: Organization): TreeCheckbox {
    // children: organization.children?.filter(o => o.id).map(child => this.createTree(child)),
    return {
      id: organization.id,
      key: organization?.name?.toUpperCase(),
      selected: true,
      // NOT IN MEP
      children: organization.children?.map(child => this.createTree(child)),
      expanded: !!organization.children?.length
    };
  }

  private getOrganizationObservable(): Observable<Organization[]> {
    return this.profileService.getMyProfile().pipe(takeUntil(this.destroy$),
      switchMap(profile => (profile.role === Role.Admin
        ? this.organizationRepository.findAllTrees()
        : profile.role === Role.Talker ? this.organizationRepository.findAll() : of([]))));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  tabs: InPageTab[] = [
    {
      icon: "users",
      color: "#8C5FB0",
      title: "Talkers",
      description: "",
      link: "talkers"
    },
    {
      icon: "check-dot",
      color: "#6AA33E",
      title: "Engagement",
      description: "",
      link: "commit"
    },
    {
      icon: "gauge",
      color: "#E37055",
      title: "Satisfaction",
      description: "",
      link: "satisfaction"
    },
    {
      icon: "heart-file",
      color: "#FF8B05",
      title: "Impact",
      description: "",
      link: "impact"
    },
  ];

  goTo(link: string) {
    let offset: number = 0;

    if (link === 'talkers') {
      this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'employeesSection');
      offset = this.talkerSection.nativeElement.offsetTop;
    }
    if (link === 'commit') {
      this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'statisticsSection');
      offset = this.commitmentSection.nativeElement.offsetTop;
    }
    if (link === 'satisfaction') {
      this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'settingsSection');
      offset = this.satisfactionSection.nativeElement.offsetTop;
    }
    if (link === 'impact') {
      this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'creditsSection');
      offset = this.impactSection.nativeElement.offsetTop;
    }

    this.wrapperContainer.nativeElement?.scrollTo({
      top: offset - 200,
      behavior: 'smooth'
    });
  }

  protected readonly capitalize = capitalize;

  protected readonly mediumCategoryColors = mediumCategoryColors;

  protected readonly DynamicType = DynamicType;

  selectThematic(slug: string) {
    const thematic = this.thematicCheckboxes.find(c => c.id === slug);
    if (thematic) {
      thematic.selected = !thematic.selected;
    }
    this.thematicCheckboxesSelected$.next(this.thematicCheckboxes.filter(checkbox => checkbox.selected));
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected$.next(this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected));
  }

  updateTreeSelection(trees: TreeCheckbox[]): void {
    this.organizationTrees = trees;
  }

  updateOrganizationsSelected(selected: string[]): void {
    this.selectedOrganizations$.next(flattenOrganizationTrees(this.organizations).filter(o => selected.includes(o.id)));
  }

  onThematicSelect(){
    this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'categoryFilter');
  }

  onDynamicSelect(){
    this.tracker.trackEvent(TrackerCategory.STATS_PILOT, TrackerAction.CLICK, 'dynamicFilter');
  }

  getEmojiColorByType(type: IconType): string {
    return getEmojiColorByType(type);
  }

  getLatestRating(rating: number): string {
    return this.feedbackStats?.latestRatings?.find(r => r.rating === rating)?.uniqueUserCount ?? '0';
  }

  downloadCsv(){
    const organizationsIds = this.selectedOrganizations$.value.map(o => o.id);
    this.feedbacksRepository.findAll(organizationsIds).subscribe((feedbacks: any) => {
      this.csvService.downloadFeebacks(feedbacks);
    });
  }

  getOrganizationBreadcrumbs(organization: Organization): BreadcrumbInput[] {
    const result: BreadcrumbInput[] = [ { displayName: organization.name, url: organization.id } ];
    let currentOrganization = organization;
    let depth = 0;
    let gotRightsOnOrganization = true;
    while (currentOrganization.parent && depth <= 2) {
      if (this.profile?.role === Role.Talker && this.profile?.organizationAssociations?.map(o => o.organization?.id)?.includes(currentOrganization.id)) {
        gotRightsOnOrganization = false;
      }
      currentOrganization = currentOrganization.parent;
      result.push({
        displayName: depth < 2 ? currentOrganization.name : '...',
        url: gotRightsOnOrganization && organization?.id && depth < 2 ? currentOrganization.id : undefined
      });
      depth += 1;

    }
    return result;
  }
}
