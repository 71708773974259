<button class="app-button pointer row gap center-children"
        [title]="title"
        [disabled]="disabled"
        [class]="{'big': isBig,
        'primary': primary,
        'outline': outline,
        'disabled': disabled,
        'icon': icon,
        'no-border': noBorder,
        'danger': danger,
        'secondary': secondary,
        'only-icon': !!content.children.length,
        'got-notification': gotNotification
}" [type]="type" (click)="click()"
>
  <span #content>
  <ng-content></ng-content>
  </span>
  <app-icon [class]="{'button-icon': !icon}" *ngIf="icon" [type]="icon"></app-icon>
</button>
