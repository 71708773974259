import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { BehaviorSubject, combineLatestWith, filter, skip, Subject, take, takeUntil } from "rxjs";

import { Checkbox } from "../../shared/components/checkmarks/checkmarks.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { capitalize } from "../../shared/tool-functions/capitalize";
import { TrackerAction, TrackerCategory } from '../../shared/types/tracker';
import { mediumCategoryColors } from "../../thematics/colors";
import { ThematicCategory, ThematicCategorySlug } from "../../thematics/models/category.model";
import { DynamicType } from "../../thematics/models/dynamic.model";
import { Thematic } from "../../thematics/models/thematic.model";
import { ThematicRepository } from "../../thematics/repositories/thematic.repository";
import { Organization } from "../models/organizations.entity";
import { OrganizationRepository } from "../repositories/organization.repository";
import { OrganizationStoreService } from "../services/organization.store.service";

@Component({
  selector: 'app-organization-edit-thematics',
  templateUrl: './organization-edit-thematics.component.html',
  styleUrls: [ './organization-edit-thematics.component.scss' ]
})
export class OrganizationEditThematicsComponent implements OnInit, OnDestroy {

  loading = true;

  thematics!: Thematic[];

  organization!: Organization;

  selectedThematicIds: string[] = [];

  destroy$ = new Subject<void>();

  selectedThematicIds$!: BehaviorSubject<string[]>;

  categories: ThematicCategory[] = [];

  categoryCheckboxes: Checkbox<ThematicCategorySlug>[] = [];

  categoryCheckboxesSelected$: BehaviorSubject<Checkbox<ThematicCategorySlug>[]> = new BehaviorSubject<Checkbox<ThematicCategorySlug>[]>([]);

  dynamicTypeCheckboxes: Checkbox<DynamicType>[] = [ {
    id: DynamicType.COACHING,
    key: "Coaching en groupe",
    selected: false,
  },
    {
      id: DynamicType.SPEECH,
      key: "Parole en groupe",
      selected: false,
    } ];

  selectionThematicCheckboxes: { thematic: Thematic, form: FormControl<boolean> }[] = [];

  shownThematics: { thematic: Thematic, form: FormControl<boolean> }[] = [];

  dynamicTypeCheckboxesSelected$: BehaviorSubject<Checkbox<DynamicType>[]> = new BehaviorSubject<Checkbox<DynamicType>[]>([]);

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly router: Router,
              private readonly thematicRepository: ThematicRepository,
              private readonly organizationRepository: OrganizationRepository,
              private readonly organizationStoreService: OrganizationStoreService,
              private readonly snackBarService: SnackbarService
  ) {
  }

  ngOnInit() {
    this.dynamicTypeCheckboxesSelected$
      .pipe(combineLatestWith(this.categoryCheckboxesSelected$),
        takeUntil(this.destroy$))
      .subscribe(([ dynamicCheckboxes, categoryCheckboxes ]) => {
        const dynamicTypes = dynamicCheckboxes.filter(c => c.selected).map(c => c.id);
        const categoriesChosen = categoryCheckboxes.filter(c => c.selected).map(c => c.id);

        this.shownThematics = this.selectionThematicCheckboxes
            ?.filter(checkbox =>
              (!dynamicTypes.length || dynamicTypes.some(type => checkbox.thematic.dynamics.map(d => d.type).includes(type)))
              && (!categoriesChosen.length || (checkbox.thematic.category?.slug && categoriesChosen.includes(checkbox.thematic.category.slug))))
          ?? [];
      });


    this.thematicRepository.getAll()
      .pipe(
        combineLatestWith(this.organizationStoreService.getObservable()
            .pipe(filter((organization): organization is Organization => !!organization),
              take(1)),
          this.thematicRepository.getCategories()
        ),
        takeUntil(this.destroy$))
      .subscribe(([ thematics, organization, categories ]) => {
        this.thematics = thematics;
        const organizationForbiddenThematicIds = organization.forbiddenThematicIds ?? [];
        this.selectedThematicIds = thematics.map(t => t.id).filter(t => !organizationForbiddenThematicIds.includes(t));
        this.selectedThematicIds$ = new BehaviorSubject<string[]>(this.selectedThematicIds);
        this.categories = categories;
        this.categoryCheckboxes = categories.map(category => ({
          selected: false,
          id: category.slug,
          key: category.name
        }));
        this.organization = organization;
        this.selectedThematicIds$
          .pipe(
            skip(1),
            takeUntil(this.destroy$))
          .subscribe(selectedIds => {
            const forbiddenThematicIds = this.thematics
              .filter(thematic => !selectedIds.includes(thematic.id))
              .map(thematic => thematic.id);
            this.organizationRepository.patch(this.organization?.id ?? '', {
              forbiddenThematicIds
            })
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.snackBarService.pushMessage('Modification enregistrée avec succès', 'success');
              });
          });

        this.selectionThematicCheckboxes = this.thematics.map(thematic => ({
          thematic,
          form: new FormControl<boolean>(!organizationForbiddenThematicIds.includes(thematic.id), { nonNullable: true })
        }));

        this.selectionThematicCheckboxes.forEach((checkbox) => {
          checkbox.form.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((value) => {
              const allowedThematicIds = this.selectedThematicIds$.getValue();
              const newList = this.thematics
                .filter(thematic => (allowedThematicIds.includes(thematic.id) && thematic.id !== checkbox.thematic.id) || value)
                .map(t => t.id);

              this.selectedThematicIds$.next(newList);
            });
        });

        this.shownThematics = this.selectionThematicCheckboxes;
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  selectCategory(slug: string) {
    const category = this.categoryCheckboxes.find(c => c.id === slug);
    if (category) {
      category.selected = !category.selected;
    }
    this.categoryCheckboxesSelected$.next(this.categoryCheckboxes.filter(checkbox => checkbox.selected));
  }

  selectDynamic(type: string) {
    const dynamic = this.dynamicTypeCheckboxes.find(c => c.id === type);
    if (dynamic) {
      dynamic.selected = !dynamic.selected;
    }
    this.dynamicTypeCheckboxesSelected$.next(this.dynamicTypeCheckboxes.filter(checkbox => checkbox.selected));
  }

  getCategoryColor(thematic: Thematic): string {
    return thematic.category?.slug ? mediumCategoryColors[thematic.category.slug] : 'white';
  }

  goToThematic(thematic: Thematic) {
    this.tracker.trackEvent(TrackerCategory.MANAGE_THEMATICS_PAGE, TrackerAction.CLICK, 'discoverThematicButton');
    this.router.navigate([ 'thematics', thematic.slug ]);
  }

  protected readonly DynamicType = DynamicType;

  protected readonly capitalize = capitalize;

  protected readonly mediumCategoryColors = mediumCategoryColors;
}

