import { Component, Input, inject } from '@angular/core';
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';

import { TrackerAction, TrackerCategory } from "../../../types/tracker";

export type GoFurtherLinkImage = 'tree-in-head' | 'coach' | 'train';

export type GoFurtherLink = {
  image: GoFurtherLinkImage;
  title: string;
  subTitle: string;
  description: string;
  link: string;
  isExternalLink: boolean;
  queryParams?: { [key: string]: string }
}

@Component({
  selector: 'app-go-further-links',
  templateUrl: './go-further-links.component.html',
  styleUrl: './go-further-links.component.scss'
})
export class GoFurtherLinksComponent {

  @Input() links: GoFurtherLink[] = [];

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly router: Router) {
  }

  clickOnLink(link: GoFurtherLink) {
    if (link.isExternalLink) {
      this.tracker.trackEvent(TrackerCategory.HOME_TALKER, TrackerAction.CLICK, `${link.link.split('/')[1]}Bloc`);
      window.open(link.link, "_blank");
    } else {
      this.tracker.trackEvent(TrackerCategory.HOME_TALKER, TrackerAction.CLICK, `${link.link.split('/')[1]}Bloc`);
      this.router.navigate([link.link], { queryParams: link.queryParams });
    }
  }
}
