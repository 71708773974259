import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { catchError, debounceTime, of, Subject, takeUntil, tap } from "rxjs";

type Card<T> = {
  data: T;
  entering: boolean;
  leaving: boolean;
};

@Component({
  selector: "app-cards-list",
  templateUrl: "./cards-list.component.html",
  styleUrls: ["./cards-list.component.scss"],
})
export class CardsListComponent<T extends { id: string } = { id: string }>
  implements OnInit, OnDestroy {
  currentItems: Card<T>[] = [];

  public readonly cardTrackBy = (index: number, card: Card<T>) =>
    card.data[this.uniqueKey];

  isLoading = true;

  destroy$ = new Subject<void>();

  loadingNextPage = false;

  private animationDuration = 500;

  @ContentChild("content", { static: false })
  contentTemplateRef!: TemplateRef<any>;

  @ViewChild("bottomTag") lastItem!: ElementRef<HTMLElement>;

  @ViewChild("list") wrapper!: ElementRef<HTMLElement>;

  @Input() items!: Subject<T[]>;

  @Input("unique-key") uniqueKey: keyof T = "id";

  @Input() loading!: Subject<void>;

  @Input() scroll!: Subject<void>;

  @Input() itemsCount?: number;

  @Input() newItems$!: Subject<T[]>;

  @Output() loadNewPage = new EventEmitter<void>();

  ngOnInit(): void {
    this.scroll
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => this.onScrollEvent());

    this.loading
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.items
      .pipe(
        tap(() => {
          this.isLoading = true;
        }),
        catchError(() => of(this.currentItems.map((item) => item.data) as T[])),
        tap((newItems: T[]) => {
          const currentItemKeys = this.currentItems.map(
            (item) => item.data[this.uniqueKey]
          );
          const newItemKeys = newItems.map((item) => item[this.uniqueKey]);

          this.currentItems = this.currentItems
            .map((item) => ({
              data: item.data,
              entering: false,
              leaving:
                !this.loadingNextPage &&
                !newItemKeys.includes(item.data[this.uniqueKey]),
            }))
            .concat(
              ...newItems
                .filter(
                  (newItem) =>
                    !currentItemKeys.includes(newItem[this.uniqueKey])
                )
                .map((item) => ({
                  data: item,
                  entering: true,
                  leaving: false,
                }))
            );

          this.isLoading = false;
          this.loadingNextPage = false;
        }),
        debounceTime(this.animationDuration),
        tap(() => {
          this.currentItems = this.currentItems
            .filter((item) => !item.leaving)
            .map((item) => ({
              data: item.data,
              entering: false,
              leaving: false,
            }));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.isLoading = false;

        if (!this.loadingNextPage) {
          this.wrapper.nativeElement.scrollTo({ top: 0, behavior: "smooth" });
          this.onScrollEvent();
        }
      });

    this.newItems$
      .pipe(
        tap((newItems) => {
          this.currentItems = this.currentItems.concat(
            newItems.map((item) => ({
              data: item,
              entering: true,
              leaving: false,
            }))
          );
        }),
        debounceTime(this.animationDuration),
        tap(() => {
          this.currentItems = this.currentItems.map((item) => ({
            data: item.data,
            entering: false,
            leaving: false,
          }));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onScrollEvent() {
    if (
      window.innerHeight >
        this.lastItem.nativeElement.getBoundingClientRect().top &&
      (this.itemsCount || 0) > this.currentItems.length
    ) {
      this.loadingNextPage = true;
      this.loadNewPage.emit();
    }
  }
}
