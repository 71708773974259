import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';
import { takeUntil } from "rxjs";
import { map, tap } from "rxjs/operators";

import { AbstractInFunnelComponent } from "../../shared/components/funnel/abstract.in-funnel.component";
import { FunnelService } from "../../shared/services/funnel.service";
import { TrackerAction, TrackerCategory } from '../../shared/types/tracker';
import { Role } from "../../users/models/users.entity";
import { ProfileService } from "../../users/services/profile.service";
import { mediumCategoryColors } from "../colors";
import { ThematicCategorySlug } from "../models/category.model";
import { Thematic } from "../models/thematic.model";

@Component({
  selector: 'app-thematic-details',
  templateUrl: './thematic-details.component.html',
  styleUrls: [ './thematic-details.component.scss' ]
})
export class ThematicDetailsComponent extends AbstractInFunnelComponent {
  thematic!: Thematic;

  workingOnBulletPoints: string[] = [];

  override isFunnelFirstStep = true;

  categorySlug?: ThematicCategorySlug;

  colorMedium!: string;

  relatedThematics: Thematic[] = [];

  isTalker = false;

  changingThematic = false;

  loaded = false;

  @ViewChild('page') elementRef!: ElementRef<HTMLElement>;

  private readonly tracker = inject(MatomoTracker);

  constructor(protected override readonly route: ActivatedRoute,
              protected override readonly funnelService: FunnelService,
              private readonly profileService: ProfileService,
              private readonly router: Router,
              ) {
    super(funnelService, route);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    this.funnelService.componentLoading$.next(!this.loaded);
  }

  override ngOnInit() {
    this.funnelService.resetScroll$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.elementRef.nativeElement.scrollTo({ top: 0, behavior: "auto" });
      });
    this.isTalker = this.profileService?.profile?.role === Role.Talker;
    this.route.data
      .pipe(
        map(data => data.thematic),
        tap((thematic: Thematic) => {
          this.thematic = thematic;
        })).subscribe(() => {
      this.previousMessage = 'Retour aux thématiques';
      super.ngOnInit();

      this.categorySlug = this.thematic.category?.slug;
      this.colorMedium = this.categorySlug ? mediumCategoryColors[this.categorySlug] : '';
      this.workingOnBulletPoints = this.thematic.workingOn.split('\n');

      this.relatedThematics = this.thematic.category?.thematics?.filter(otherThematic => otherThematic.id !== this.thematic.id) ?? [];

      this.loaded = true;
      this.funnelService.componentLoading$.next(!this.loaded);
    });
  }

  editThematic() {
    this.router.navigate([ 'thematics', this.thematic.slug, "edit" ]);
  }


  goToThematic(slug: string) {
    this.changingThematic = true;
    this.tracker.trackEvent(TrackerCategory.TRAINS_TALKER, TrackerAction.CLICK, 'discoverThematicButton');
    this.router.navigate([ 'thematics', slug ]);
  }
}
