import { Type } from "class-transformer";

import { ThematicCategory } from "../../thematics/models/category.model";
import { Thematic } from "../../thematics/models/thematic.model";

enum ObjectiveEnum {
    INSPIRE = 'inspire',
    PREPARE = 'prepare',
    TRANSFORM = 'transform',
    ACT = 'act',
}

enum PillarEnum {
    INDIVIDUAL = 'individual',
    ENVIRONMENT = 'environment',
    EVENT = 'event',
}

enum FormatEnum {
    VIDEO = 'video',
    PODCAST = 'podcast',
    PRACTICAL_SHEET = 'pratical_sheet',
    TEST = 'test',
}

export interface IContent {
    id: string;
    title: string;
    description: string;
    image: string;
    resource: string;
    objective: ObjectiveEnum;
    format: FormatEnum;
    readingDuration: number;
    thematics: Thematic[];
    thematicCategories: ThematicCategory[];
}

export class Content implements IContent {
    id!: string;

    title!: string;

    description!: string;

    image!: string;

    resource!: string;

    objective!: ObjectiveEnum;

    pillars!: any [];

    format!: FormatEnum;

    readingDuration!: number;

    @Type(() => Thematic)
    thematics!: Thematic[];

    @Type(() => ThematicCategory)
    thematicCategories!: ThematicCategory[];

    @Type(() => Date)
    createdAt!: Date;
}