import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatestWith, Subject, takeUntil } from "rxjs";

import { Organization } from "../../../../organizations/models/organizations.entity";
import { OrganizationStoreService } from "../../../../organizations/services/organization.store.service";
import { Role, SubRole } from "../../../../users/models/users.entity";
import { ProfileService } from "../../../../users/services/profile.service";
import { InPageTab } from "../in-page-tabs/in-page-tabs.component";

@Component({
  selector: 'app-home-page-tabs',
  templateUrl: './home-page-tabs.component.html',
})
export class HomePageTabsComponent implements OnInit, OnDestroy {

  public role!: SubRole;

  public tabs: InPageTab[] = [];

  public organization?: Organization;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly profileService: ProfileService,
              private readonly organizationStoreService: OrganizationStoreService,) {
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.profileService.getObservable()
      .pipe(
        combineLatestWith(this.organizationStoreService.getObservable()),
        takeUntil(this.destroy$))
      .subscribe(([ profile, organization ]) => {
        this.role = organization ? 'pilot' : profile.role;
        this.organization = organization;
        this.tabs = this.getTabs();
      });
  }

  private getTabs(): InPageTab[] {
    switch (this.role) {
      case Role.Talker:
        return [ {
          icon: "heart",
          color: "#FF8B05",
          title: "Mon espace",
          description: "Votre historique et l’évolution de votre bien-être",
          link: "my-space"
        },
          {
            icon: "calendar",
            color: "#2D7DC8",
            title: "Parcours",
            description: "Rejoignez les parcours déjà lancés",
            link: "available-trains"
          },
          {
            icon: "leaves",
            color: "#2CBBB6",
            title: "Les thématiques",
            description: "Découvrez le portefeuille des thématiques",
            link: "thematics"
          },
          // NOT IN MEP
          // {
          //   icon: "play-button",
          //   color: "#E37055",
          //   title: "Contenu",
          //   description: "Émissions, vidéos, fiches pratiques et podcasts pour découvrir et pratiquer vous même",
          //   link: "contents"
          // }
          {
            icon: "user",
            color: "#E37055",
            title: "Paramètres",
            description: "Gérez les informations de votre profil",
            link: "my-profile"
          }
        ];
      case 'pilot':
        return [ {
          icon: "users",
          color: "#2D7DC8",
          title: "Collaborateurs",
          description: "Invitez et administrez les utilisateurs ",
          link: `organizations/${this.organization?.id}/talkers`
        },
          {
            icon: "chart",
            color: "#FF8B05",
            title: "Statistiques",
            description: "Suivez les usages et les impacts positifs",
            link:  `organizations/${this.organization?.id}/statistics`
          },
          {
            icon: "parameters",
            color: "#2CBBB6",
            title: "Paramètres",
            description: "Gérez les informations de votre compte",
            link: `organizations/${this.organization?.id}/edit`
          },
          {
            icon: "crawn",
            color: "#E37055",
            title: "Crédits",
            description: "Pilotez votre consommation",
            link: `organizations/${this.organization?.id}/units`
          }, ];
      case Role.Admin:
        return [ {
          icon: "calendar",
          color: "#FF8B05",
          title: "Parcours",
          description: "Retrouver la liste des parcours en cours et terminés",
          link: "trains"
        },
          {
            icon: "building",
            color: "#2D7DC8",
            title: "Organisations",
            description: "Retrouver la liste des entreprises clientes",
            link: "organizations"
          },
          {
            icon: "leaves",
            color: "#2CBBB6",
            title: "Thématiques",
            description: "Éditer les thématiques et leur contenu",
            link: "thematics"
          },
          {
            icon: "users",
            color: "#E37055",
            title: "Utilisateurs",
            description: "Retrouver la liste des utilisateurs de la plateforme",
            link: "users"
          },
          // {
          //   icon: "parameters",
          //   color: "#ffc200",
          //   title: "Configuration",
          //   description: "Éditer la configuration de la plateforme dans votre espace admin",
          //   link: "users"
          // },
          {
            icon: "chart",
            color: "#ca81e7",
            title: "Dashboard",
            description: "Retrouver les statistiques et les retours des parcours",
            link: "statistics"
          },

        ];
      case Role.Sherpa:
        return [ {
          icon: "calendar",
          color: "#6AA33E",
          title: "Parcours",
          description: "Retrouvez vos parcours suivis",
          link: "my-trains"
        },
          {
            icon: "chart",
            color: "#FF8B05",
            title: "Statistiques",
            description: "Suivez les usages et les impacts positifs",
            link:  "statistics"
          },
          {
            icon: "parameters",
            color: "#2CBBB6",
            title: "Mon profil Sherpa",
            description: "Gérer les informations de votre compte",
            link: "my-profile"
          },
          {
            icon: "bubble-smile",
            color: "#2D7DC8",
            title: "Contacter We Talk",
            description: "Un souhait, une question ? Contactez-nous",
            link: "questions"
          }, ];
      default:
        return [];
    }
  }
}
