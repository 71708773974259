<div class="list-wrapper" [class.with-breadcrumb]="withBreadcrumb">
  <div class="list-header">
    <h1 class="title">
      {{ totalCount }} {{ nameCapitalized
      }}{{ isWrittenInPlural ? "" : totalCount && totalCount > 1 ? "s" : "" }}
    </h1>
    <div class="header-inputs row">
      <app-input
        [margin-bottom]="false"
        class="input"
        type="search"
        [placeholder]="
          'Rechercher un' + (isFemaleName ? 'e' : '') + ' ' + name + '...'
        "
        [inputField]="searchForm"
        [gotSubMenu]="!!propertiesFilters$ && !!filterForm"
        [hasFilters]="hasFilters"
      >
        <div class="filter-menu">
          <div class="row">
            <h2>Filtre {{ name }}{{ isWrittenInPlural ? "" : "s" }}</h2>
          </div>
          <ng-content select="[filter]"></ng-content>
        </div>
      </app-input>
      <div class="margin-left text-large center-children-vertically">
        <app-icon
          button
          type="dots"
          title="Plus d'options"
          *ngIf="
            download.observed ||
            add.observed ||
            optionsContent?.elementRef?.nativeElement?.children?.length
          "
          [dropdownTriggerFor]="moreOptionsMenu"
        />
      </div>
      <app-dropdown #moreOptionsMenu>
        <div
          *ngIf="add.observed"
          class="dropdown-item row gap"
          (click)="addOne()"
        >
          <span class="grow"
            >Ajouter un{{ isFemaleName ? "e" : "" }} {{ name }}</span
          >
          <app-icon size="16" type="add"></app-icon>
        </div>
        <div
          *ngIf="download.observed"
          class="dropdown-item row gap"
          (click)="downloadCsv()"
        >
          <span class="grow">Télécharger les données</span>
          <app-icon size="16" type="upload"></app-icon>
        </div>
        <ng-content #moreOptionsContent select="[options]"></ng-content>
      </app-dropdown>
    </div>
  </div>

  <app-cards-list
    [items]="items$"
    [loading]="loading$"
    [scroll]="scroll$"
    [unique-key]="uniqueKey"
    [itemsCount]="totalCount"
    [newItems$]="newItems$"
    (loadNewPage)="loadNextPage()"
    class="list"
    (scroll)="onScrollEvent()"
  >
    <ng-template let-item #content>
      <ng-container
        *ngIf="cardTemplateRef"
        [ngTemplateOutlet]="cardTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      >
      </ng-container>
    </ng-template>
  </app-cards-list>
</div>
