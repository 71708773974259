import { booleanAttribute, Component, EventEmitter, inject, Input, OnChanges, Output, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { MatomoTracker } from 'ngx-matomo-client';

import { TrackerAction, TrackerCategory } from "../../../types/tracker";
import { IconType } from "../../icon/icon.component";


export type InPageTab = {
  icon: IconType;
  color: string;
  title: string;
  description?: string;
  link: string;
}

@Component({
  selector: 'app-in-page-tabs',
  templateUrl: './in-page-tabs.component.html',
  styleUrl: './in-page-tabs.component.scss'
})
export class InPageTabsComponent implements OnChanges {

  @Input() tabs: InPageTab[] = [];

  @Input() showArrowIcons?: boolean = true;

  @Input({ transform: booleanAttribute }) arrowDown = false;

  @Input() withClick?: boolean = true;

  @Output() clickLink = new EventEmitter<string>();

  firstPartTabs: InPageTab[] = [];

  secondPartTabs: InPageTab[] = [];

  private readonly tracker = inject(MatomoTracker);

  constructor(private readonly router: Router) {
  }

  ngOnChanges() {
    if (this.tabs.length > 5) {
      this.firstPartTabs = this.tabs.slice(0, Math.ceil(this.tabs.length / 2));
      this.secondPartTabs = this.tabs.slice(Math.ceil(this.tabs.length / 2), this.tabs.length);
    } else {
      this.firstPartTabs = this.tabs;
    }
  }

  goTo(link: string) {
    if (this.clickLink.observed) {
      this.clickLink.next(link);
    } else {
      this.tracker.trackEvent(TrackerCategory.HOME_TALKER, TrackerAction.CLICK, `${link}-section`);
      this.router.navigate([ link ]);
    }
  }
}

